import { useCallback, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { query, where } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { useCollection } from 'react-firebase-hooks/firestore'
import { DateTime } from 'luxon'
import OPageWrapper from '@/components/organisms/OPageWrapper'
import useStore from '@/stores/useStore'

import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'

import { ROUTES } from '@/const/routes'

import { jobsCollectionRef } from '@/services/Firebase'
import { GUNSLINGER_JOB_STATUSES } from '@/const/const'

import { Badge } from '@/components/catalyst/badge'

import MStopVMButton from '@/components/molecules/iteration-details/MStopVMButton'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function OOrgProjectVMTable({ organization, onVMStopped = () => {} }) {
  return (
    <div className="mt-12 rounded-md bg-white px-4 py-8 ring-1 ring-zinc-200 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{organization.name}</h1>
          <p className="mt-2 text-sm text-gray-700">
            All VMSs that are currently running for this organization
          </p>
        </div>
      </div>
      {organization?.projects?.map(project => (
        <div
          key={project.id}
          className="-mx-4 mt-10 bg-white ring-1 ring-zinc-100 sm:mx-0 sm:rounded-lg"
        >
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-96 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {project.name} (Iteration Id)
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-zinc-900 lg:table-cell"
                >
                  Job Id
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-zinc-900 lg:table-cell"
                >
                  Job created at
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm  font-normal text-zinc-900 lg:table-cell"
                >
                  Job updated at
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm  font-normal text-zinc-900"
                >
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Select</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {project?.jobs?.map((job, index) => (
                <tr key={job.id}>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-transparent',
                      'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                    )}
                  >
                    <div className="font-medium text-gray-900 hover:underline">
                      <Link
                        target="_blank"
                        to={`/projects/${project.id}?iteration=${job.iterationId}`}
                      >
                        {job.iterationId}
                      </Link>
                    </div>

                    {index !== 0 ? (
                      <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                    ) : null}
                  </td>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {job.id}
                  </td>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {job.createdAtRelative}
                  </td>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                    )}
                  >
                    {job.updatedAtRelative}
                  </td>

                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-gray-200',
                      'px-3 py-3.5 text-sm text-gray-500'
                    )}
                  >
                    <div className="flex items-center justify-center">
                      <Badge color="zinc" className="font-mono">
                        {job.status}
                      </Badge>
                    </div>
                  </td>
                  <td
                    className={classNames(
                      index === 0 ? '' : 'border-t border-transparent',
                      'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
                    )}
                  >
                    <MStopVMButton
                      iterationId={job.iterationId}
                      status={job.status}
                      onVMStopped={onVMStopped}
                    />
                    {index !== 0 ? (
                      <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}

OOrgProjectVMTable.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        jobs: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            iterationId: PropTypes.string,
            status: PropTypes.string,
            createdAtRelative: PropTypes.string,
            updatedAtRelative: PropTypes.string,
          })
        ),
      })
    ),
  }),
  onVMStopped: PropTypes.func,
}

const BREADCRUMBS = [
  {
    name: 'Manage VMs',
    href: ROUTES.MANAGE_VMS,
    current: true,
  },
]

export default function PManageVMs() {
  const projects = useStore(state => state.projects)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const organizations = useStore(state => state.organizations)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)
  const getProjectBrokenDownByOrganization = useStore(
    state => state.getProjectBrokenDownByOrganization
  )

  const projectsByOrganization = useMemo(() => {
    if (projects && projects.length > 0 && organizations && organizations.length > 0) {
      return getProjectBrokenDownByOrganization()
    }
    return null
  }, [projects, getProjectBrokenDownByOrganization, organizations])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  const jobsQuery = useMemo(() => {
    return query(
      jobsCollectionRef,
      where('status', 'in', [
        GUNSLINGER_JOB_STATUSES.RUNNING,
        GUNSLINGER_JOB_STATUSES.STARTING,
        GUNSLINGER_JOB_STATUSES.STARTED,
        GUNSLINGER_JOB_STATUSES.QUEUED,
      ])
    )
  }, [])

  // Actual iterations retrieval
  const [jobsSnapshot, jobsSnapshotLoading] = useCollection(jobsQuery)

  const jobs = useMemo(() => {
    if (!jobsSnapshot) {
      return []
    }
    return jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }, [jobsSnapshot])

  const jobsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (jobs) {
      return jobs?.map(job => ({
        ...job,
        updatedAtRelative: DateTime.fromJSDate(job?.updatedAt?.toDate() || Date.now()).toFormat(
          'LLL dd, hh:mm a'
        ),
        createdAtRelative: DateTime.fromJSDate(job?.createdAt?.toDate() || Date.now()).toFormat(
          'LLL dd, hh:mm a'
        ),
      }))
    }
  }, [jobs])

  const organizationWithProjectsAndJobs = useMemo(() => {
    if (!projectsByOrganization || !jobsWithRelativeTime) {
      return null
    }

    const organizations = []

    projectsByOrganization.forEach(organization => {
      const projects = []
      organization.projects.forEach(project => {
        const projectJobs = jobsWithRelativeTime
          .filter(job => job.projectId === project.id)
          .sort((a, b) => (a.updatedAt?.seconds < b.updatedAt?.seconds ? -1 : 1))
        if (projectJobs.length > 0) {
          projects.push({ ...project, jobs: projectJobs })
        }
      })
      if (projects.length > 0) {
        organizations.push({ ...organization, projects })
      }
    })
    return organizations
  }, [projectsByOrganization, jobsWithRelativeTime])

  const handleVMStoppedForIteration = useCallback(iterationId => {
    console.log('VM stopped for iteration', iterationId)
  }, [])

  if (!isSuperAdmin) {
    return null
  }

  if (!isAfterInitialLoad || jobsSnapshotLoading) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div role="status" className="px-2 py-6 lg:px-24 lg:py-12 ">
          <MCardTableSkeleton
            title="Loading VMS..."
            howManyProjectsToFake={20}
            labels={['Project', 'Job id', 'Job created at', 'Job updated at', 'Status']}
          />
        </div>
      </OPageWrapper>
    )
  }

  if (!projectsByOrganization || projectsByOrganization.length === 0) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div className="mt-12 text-4xl font-bold tracking-tight text-gray-400 sm:text-6xl">
          No Running VMSs found
        </div>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={BREADCRUMBS}>
      <div className="pb-48">
        {organizationWithProjectsAndJobs.map(organization => (
          <OOrgProjectVMTable
            key={organization.id}
            organization={organization}
            onVMStopped={handleVMStoppedForIteration}
          />
        ))}
      </div>
    </OPageWrapper>
  )
}
