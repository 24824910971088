import PropTypes from 'prop-types'
import { Button } from '@/components/catalyst/button'

export default function MFourOFour({
  title = 'Page not found',
  description = 'We couldn’t find the page you’re looking for.',
  href = '/',
  cta = 'Go back home',
}) {
  return (
    <main className="my-12 grid h-full place-items-center bg-white px-6 py-24 sm:my-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-zinc-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {title}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">{description}</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button href={href}>{cta}</Button>
        </div>
      </div>
    </main>
  )
}

MFourOFour.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
  cta: PropTypes.string,
}
