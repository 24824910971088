const repositoryDefaultFields = [
  {
    name: 'Repository URI',
    key: 'repoURI',
    requiresDefaultValue: false,
    hidden: false,
    description: 'Log into the GitHub repository and copy the URI. git@github.com:org/repo.git',
    type: 'text-short',
    placeholder: 'git@github.com:org/repo.git',
    dontEncrypt: true,
  },
  {
    name: 'Start commit hash',
    key: 'startCommitHash',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'The commit hash where the iteration starts. You can get this from the GitHub repository. Looks like a key of numbers and letters.',
    type: 'text-short',
    placeholder: '1234567890abcdef',
    dontEncrypt: true,
  },
  {
    name: 'GitHub key',
    key: 'githubKey',
    requiresDefaultValue: false,
    hidden: false,
    description:
      'The key to access the GitHub repository. This is a private key. Do not share this with anyone. The key will be encrypted.',
    type: 'text-long',
    placeholder: '-----BEGIN....',
    dontEncrypt: false,
  },
]

export { repositoryDefaultFields }
