import posthog from 'posthog-js'
import PropTypes from 'prop-types'
import { PostHogProvider } from 'posthog-js/react'
let isPosthogInitialized = false
if (typeof window !== 'undefined') {
  const posthogKey = import.meta.env.VITE_POSTHOG_KEY
  const posthogHost = import.meta.env.VITE_POSTHOG_HOST
  if (!posthogKey || !posthogHost || posthogKey === '' || posthogHost === '') {
    console.error('PostHog key or host is missing')
  } else {
    posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
      autocapture: false, // disable autocapture
    })
    isPosthogInitialized = true
  }
}
export default function CSPostHogProvider({ children }) {
  if (!isPosthogInitialized) {
    return <>{children}</>
  } else {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>
  }
}
CSPostHogProvider.propTypes = {
  children: PropTypes.node,
}
