import { useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'
import { Avatar } from '@/components/catalyst/avatar'

import MProjectCostSummaryCard from '@/components/molecules/project-details/MProjectCostSummaryCard'

function MProjectHeaderCompact({ image = '1', projectName = 'Project name', onExpand = () => {} }) {
  return (
    <div className="flex items-center justify-between overflow-hidden rounded-lg bg-white shadow-md">
      <h2 className="sr-only" id="profile-overview-title">
        Project Header
      </h2>
      <div className="flex items-center justify-start space-x-4 p-4">
        {/* <img className="mx-auto h-20 w-20 rounded-full" src={user.imageUrl} alt="" /> */}
        <Avatar
          src={image ? `/project-covers/${image}.svg` : `/project-covers/7.svg`}
          className="size-6"
        />

        <div className="col-span-2 flex flex-col items-start justify-center">
          <p className="font-mono text-xs font-semibold text-zinc-500">Project</p>
          <div className="flex items-center">
            <p className="text-base font-bold text-gray-900">{projectName}</p>
            <div className="px-2">
              <Button plain onClick={onExpand} className="cursor-pointer">
                <ChevronDownIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MProjectHeaderCompact.propTypes = {
  image: PropTypes.string,
  projectName: PropTypes.string,
  onExpand: PropTypes.func,
}

/**
 * @param {object} props
 * @param {string} props.image
 * @param {string} props.teamName
 * @param {string} props.projectName
 * @param {string} props.organizationName
 * @param {number} props.inputTokens
 * @param {number} props.outputTokens
 * @param {number} props.elapsedTime
 * @param {boolean} props.areAllIterationsDone
 * @param {number} props.cost
 * @returns {JSX.Element}
 */
export default function MProjectHeader({
  image,
  teamName = 'Team',
  projectName = 'Project',
  organizationName = 'Organization',
  inputTokens = 0,
  outputTokens = 0,
  elapsedTime = 0,
  areAllIterationsDone = true,
  cost = 0,
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  if (!isExpanded) {
    return (
      <MProjectHeaderCompact
        image={image}
        projectName={projectName}
        onExpand={() => {
          setIsExpanded(true)
        }}
      />
    )
  }
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow-xl">
      <h2 className="sr-only" id="profile-overview-title">
        Project Header
      </h2>
      <div className="grid grid-cols-9 gap-8 bg-white p-6">
        <div className="col-span-4 sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex sm:space-x-5">
            <div className="flex-shrink-0">
              {/* <img className="mx-auto h-20 w-20 rounded-full" src={user.imageUrl} alt="" /> */}
              <Avatar
                src={image ? `/project-covers/${image}.svg` : `/project-covers/7.svg`}
                className="size-20"
              />
            </div>
            <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
              <p className="text-sm font-medium text-gray-600">{organizationName}</p>
              <div className="flex items-center">
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">{projectName}</p>
                <div className="px-2">
                  <Button
                    plain
                    onClick={() => {
                      setIsExpanded(false)
                    }}
                    className="cursor-pointer"
                  >
                    <ChevronUpIcon />
                  </Button>{' '}
                </div>
              </div>
              <p className="text-sm font-medium text-gray-600">{teamName}</p>
              <div className="pt-5">
                <Button
                  outline
                  onClick={() => {
                    alert('Not implemented')
                  }}
                >
                  See project permissions
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2" />
        <MProjectCostSummaryCard
          className="col-span-3"
          areAllIterationsDone={areAllIterationsDone}
          inputTokens={inputTokens}
          outputTokens={outputTokens}
          elapsedTime={elapsedTime}
          cost={cost}
        />
      </div>
    </div>
  )
}

MProjectHeader.propTypes = {
  image: PropTypes.string,
  teamName: PropTypes.string,
  projectName: PropTypes.string,
  organizationName: PropTypes.string,
  inputTokens: PropTypes.number,
  outputTokens: PropTypes.number,
  elapsedTime: PropTypes.number,
  cost: PropTypes.number,
  areAllIterationsDone: PropTypes.bool,
}
