import PropTypes from 'prop-types'
import { useState } from 'react'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { AcademicCapIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

function ReflectorContent({
  plan,
  advices,
  reflections,
  issueAnalysis,
  stepBoundary,
  issueResolution,
}) {
  const descriptionContent = []
  if (reflections && Array.isArray(reflections) && reflections.length > 0) {
    descriptionContent.push(
      <div key="advices" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Reflection:</div>
        <ul className="max-w-prose list-disc pt-1">
          {reflections.map((reflection, index) => (
            <li key={`advice-${reflection?.id || index}`} className="ml-4 list-item list-disc">
              {reflection?.description || 'No relevant info found'}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  if (issueAnalysis) {
    descriptionContent.push(
      <div key="issue-analysis" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Issue analysis:</div>
        <div className="max-w-prose pt-1">{issueAnalysis}</div>
      </div>
    )
  }

  if (issueResolution) {
    descriptionContent.push(
      <div key="issue-resolution" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Issue resolution:</div>
        <div className="max-w-prose pt-1">{issueResolution}</div>
      </div>
    )
  }

  if (stepBoundary) {
    descriptionContent.push(
      <div key="step-boundry" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Step Boundary:</div>
        <div className="max-w-prose pt-1">{stepBoundary}</div>
      </div>
    )
  }

  if (plan && Array.isArray(plan) && plan.length > 0) {
    descriptionContent.push(
      <div key="plan" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Plan:</div>
        <ul className="max-w-prose list-disc pt-1">
          {plan.map((planItem, index) => (
            <li key={`plan-${planItem?.id || index}`} className="ml-4 list-item list-disc">
              {planItem?.description || 'No description '}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  if (advices && Array.isArray(advices) && advices.length > 0) {
    descriptionContent.push(
      <div key="advices" className="whitespace-pre-line pt-3">
        <div className="text-xs font-bold">Advices:</div>
        <ul className="max-w-prose list-disc pt-1">
          {advices.map((advice, index) => (
            <li
              key={`advice-${advice?.next_action_id || index}`}
              className="ml-4 list-item list-disc"
            >
              {advice?.relevant_info || 'No relevant info found'}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return (
    <div className="">
      {/* <div className="border border-dashed border-zinc-200"></div> */}
      <AActionContentDescription content={descriptionContent} />
    </div>
  )
}

export default function ReflectorAction({ action, isLast = false }) {
  const [showDetails, setShowDetails] = useState(false)

  const reflections = action?.args?.reflection || action?.args?.Reflection
  const reflection = action?.args?.reflection_summary || 'No reflection summary'
  const advices = action?.args?.advices || []
  const plan = action?.args?.plan || []
  const issueAnalysis = action?.args?.issue_analysis
  const issueResolution = action?.args?.issue_resolution
  const stepBoundary = action?.args?.step_boundary

  // if action?.args?.plan is an array

  const descriptionContent = [
    <div key="reflection" className="whitespace-pre-line">
      {reflection}
    </div>,
  ]

  return (
    <GenericAction action={action} actionIcon={AcademicCapIcon} isLast={isLast}>
      <AContentTitle action={action} title="Reflector" />
      <AActionContentGenericLayout>
        <div
          className="cursor-pointer"
          onClick={() => {
            setShowDetails(!showDetails)
          }}
        >
          <AActionContentDescription content={descriptionContent} />
          {showDetails && (
            <ReflectorContent
              reflections={reflections}
              advices={advices}
              plan={plan}
              issueAnalysis={issueAnalysis}
              stepBoundary={stepBoundary}
              issueResolution={issueResolution}
            />
          )}
        </div>
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ReflectorAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
