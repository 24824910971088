import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function AActionContentUserSuggestion({ content }) {
  const className = classNames(
    'p-3 rounded-sm mt-1 break-all bg-amber-300 text-zinc-900 flex items-center text-sm font-semibold'
  )
  return <div className={className}>{content}</div>
}

AActionContentUserSuggestion.propTypes = {
  content: PropTypes.string,
}
