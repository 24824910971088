import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { PencilSquareIcon } from '@heroicons/react/24/solid'

import { Textarea } from '@/components/catalyst/textarea'
import { Button } from '@/components/catalyst/button'

import { createIterationCommandFirebaseFunction } from '@/services/Firebase'

import { ITERATION_COMMANDS } from '@/const/const'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const SHOULD_START_GUNSLINGER = window?.location?.host?.startsWith('old.') ? false : true

export default function MRestartFromAction({ action }) {
  const [showUserInput, setShowUserInput] = useState(false)
  const [isSendingCommand, setIsSendingCommand] = useState(false)

  const [userInput, setUserInput] = useState('')

  const sendIterationCommand = useCallback(async () => {
    setIsSendingCommand(true)

    const commandArgs = {
      rollback: {
        type: 'action',
        elementIndex: action.elementIndex,
        stepIndex: action.stepIndex,
        actionIndex: action.index,
      },
    }

    if (userInput?.length > 0) {
      commandArgs.suggestion = userInput
    }
    await createIterationCommandFirebaseFunction({
      iterationId: action.iterationId,
      command: ITERATION_COMMANDS.RESUME,
      commandArgs,
      dontStartGunslinger: !SHOULD_START_GUNSLINGER,
    })

    setIsSendingCommand(false)
    setShowUserInput(false)
    setUserInput('')

    if (commandArgs?.suggestion) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ROLLBACK_COMMENT, {
        iterationId: action.iterationId,
        elementIndex: action.elementIndex,
        stepIndex: action.stepIndex,
        actionIndex: action.index,
        suggestion: commandArgs.suggestion,
      })
    } else {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ROLLBACK_NO_COMMENT, {
        iterationId: action.iterationId,
        elementIndex: action.elementIndex,
        stepIndex: action.stepIndex,
        actionIndex: action.index,
      })
    }
  }, [action, userInput])

  return (
    <>
      {!showUserInput && (
        <div className="flex w-full items-center justify-center">
          <div className="flex-grow border border-dashed border-zinc-200" />
          <div className="mx-2 flex-shrink-0">
            <Button
              outline
              className="group h-8 cursor-pointer"
              onClick={() => {
                setShowUserInput(!showUserInput)
              }}
            >
              <PencilSquareIcon className="h-4 w-4" />
              <div className="hidden font-normal text-zinc-600 group-hover:inline-block">
                Restart from this point
              </div>
            </Button>
          </div>
          <div className="w-6 border border-dashed border-zinc-200" />
        </div>
      )}
      {showUserInput && (
        <>
          <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" />
          <div className="ml-12 flex  flex-col items-center justify-center rounded-md border border-zinc-200 bg-zinc-50 p-4 shadow-inner">
            <Textarea
              className="w-full"
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
              placeholder="Give your hint..."
            />
            <div className="mt-4 flex w-full justify-end">
              <Button
                outline
                className="cursor-pointer"
                disabled={isSendingCommand}
                onClick={() => {
                  setShowUserInput(!showUserInput)
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isSendingCommand}
                className="ml-4 cursor-pointer"
                color="red"
                onClick={sendIterationCommand}
              >
                {isSendingCommand ? 'Working...' : 'Restart from this point'}
              </Button>
            </div>
            <div className="mt-4 flex w-full items-center justify-end">
              <p className="text-xs text-zinc-500">
                This will remove all actions taken after this point and re-run with your suggestion
              </p>
            </div>
          </div>
          <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" />
        </>
      )}
    </>
  )
}

MRestartFromAction.propTypes = {
  action: PropTypes.object,
}
