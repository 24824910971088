import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function ListFilesAction({ action, isLast = false }) {
  let detailsContent = `## OUTPUT:\n`
  action?.outputs?.[0]?.files.forEach((file, index) => {
    detailsContent += `${index + 1}: ${file}\n`
  })
  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={DocumentMagnifyingGlassIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="List files" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment || action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ListFilesAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
