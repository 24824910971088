import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage, Field, Label } from '@/components/catalyst/fieldset.jsx'
import { Input } from '@/components/catalyst/input.jsx'
import { Textarea } from '@/components/catalyst/textarea.jsx'

const MTeamForm = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext() // Retrieve the context of the parent form

  return (
    <>
      <div>
        <Field>
          <Label>Team name</Label>
          <Input
            {...register('team.name', {
              required: 'Team name is required',
            })}
            invalid={!!errors.team?.name}
          />
        </Field>
        {errors.team?.name && <ErrorMessage className="">{errors.team?.name.message}</ErrorMessage>}
      </div>

      <div>
        <Field>
          <Label>Team description</Label>
          <Textarea
            {...register('team.description', {})}
            invalid={!!errors.team?.description}
            rows={10}
          />
        </Field>
        {errors.team?.description && (
          <ErrorMessage className="pt-0">{errors.team?.description.message}</ErrorMessage>
        )}
      </div>
    </>
  )
}

MTeamForm.PropTypes = {}

export default MTeamForm
