import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { SparklesIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function DefaultAction({ action, isLast = false }) {
  return (
    <GenericAction action={action} detailsContent="" actionIcon={SparklesIcon} isLast={isLast}>
      <AContentTitle action={action} title="function" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DefaultAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
