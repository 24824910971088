import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { PhotoIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentFiles from '@/components/actions/components/AActionContentFiles.jsx'

export default function GenerateImageAction({ action, isLast = false }) {
  const filePath = action?.args?.full_file_name_and_path
  const imagePrompt = action?.args?.text || action?.args?.prompt

  return (
    <GenericAction
      action={action}
      detailsContent={`# Generate image\n${filePath}\n\n## Prompt:\n${imagePrompt}\n\n## Size: \n${action?.args?.size}\n\n## Comment: \n${action?.args?.comment}\n\n`}
      actionIcon={PhotoIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Generate image" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={imagePrompt} />
        <AActionContentFiles content={'< ' + filePath} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

GenerateImageAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
