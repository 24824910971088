import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useFieldArray, useForm } from 'react-hook-form'
import { Button } from '@/components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog'

import { Description, ErrorMessage, Field, FieldGroup, Label } from '@/components/catalyst/fieldset'
import { Textarea } from '@/components/catalyst/textarea'
import { Input } from '@/components/catalyst/input'
import { useToast } from '@/components/ui/use-toast'
import useStore from '@/stores/useStore.js'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox.jsx'
import { getIterationDefault, updateProjectFirebaseFunction } from '@/services/Firebase.js'
import { repositoryDefaultFields } from '@/const/defaults.js'

function useUpdateProject() {
  const [isMutationInProgress, setIsMutationInProgress] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const mutationFn = async payload => {
    // const { organizationId, teamId, formData, usecaseFields, templateId } = data
    if (isMutationInProgress) {
      return
    }
    setIsMutationInProgress(true)
    setError(null)

    try {
      const response = await updateProjectFirebaseFunction(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsMutationInProgress(false)
    }
  }

  function resetFn() {
    setIsMutationInProgress(false)
    setError(null)
    setResponse(null)
  }

  return { response, isMutationInProgress, error, mutationFn, resetFn }
}

function MUpdateProjectForm({
  templates,
  teams,
  project,
  iterationDefault,
  onClose,
  onCompleted = data => {
    console.log('default function called on MUpdateProjectDialog with data: ', data)
  },
}) {
  const {
    response,
    mutationFn: updateProjectFn,
    error: updateError,
    isMutationInProgress: isUpdating,
    resetFn,
  } = useUpdateProject()

  const { toast } = useToast()

  const [selectedTemplateId, setSelectedTemplateId] = useState(iterationDefault.templateId || null)
  const [selectedTeamId, setSelectedTeamId] = useState(project.teamId)

  const selectedTemplate = useMemo(() => {
    return templates[selectedTemplateId]
  }, [selectedTemplateId, templates])

  function getValueFromIterationDefaultField(iterationDefault, namespace, key) {
    const iterationDefaultField = iterationDefault[namespace][key]
    if (typeof iterationDefaultField === 'object' && iterationDefaultField?.value) {
      return iterationDefaultField?.value
    } else {
      return iterationDefaultField
    }
  }

  const defaultValues = useMemo(() => {
    return {
      name: project.name,
      description: project.description,
      repository: repositoryDefaultFields.map(field => ({
        ...field,
        value: getValueFromIterationDefaultField(iterationDefault, 'repository', field.key),
      })),
      environment: selectedTemplate?.iterationDefaultsTemplate?.environment?.fields.map(field => ({
        ...field,
        value: getValueFromIterationDefaultField(iterationDefault, 'environment', field.key),
      })),
    }
  }, [project])

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: defaultValues,
  })

  const { fields: repositoryFields } = useFieldArray({
    control,
    name: 'repository',
  })

  const { fields: environmentFields } = useFieldArray({
    control,
    name: 'environment',
  })

  useEffect(() => {
    resetForm(defaultValues)
  }, [defaultValues, resetForm])

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post iteration creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Project updated successfully! 🎉',
      })
      onCompleted(response?.data || {})
      resetFn()
      handleClose()
    }
    if (updateError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating iteration:', updateError)
      resetFn()
    }
  }, [response, updateError, toast, resetFn, handleClose, onCompleted])

  // prepare form data for project to be created
  const onSubmit = useCallback(
    data => {
      const updatedFields = Object.keys(dirtyFields).reduce(
        (acc, fieldKey) => {
          const dirtyValue = dirtyFields[fieldKey]
          if (Array.isArray(dirtyValue)) {
            acc.iterationDefaults[fieldKey] = {}
            dirtyValue.forEach((item, index) => {
              const updatedEnv = data[fieldKey][index]
              acc.iterationDefaults[fieldKey][updatedEnv.key] = {
                value: updatedEnv.value,
                dontEncrypt: updatedEnv.dontEncrypt,
              }
            })
          } else {
            acc[fieldKey] = data[fieldKey]
          }

          return acc
        },
        { iterationDefaults: {} }
      )

      if (!isUpdating) {
        updateProjectFn({
          projectId: project.id,
          updatedFields,
        })
      }
    },
    [updateProjectFn, project.id, isUpdating]
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Update project {project.name}</DialogTitle>
      <DialogBody>
        <div className="overflow-y-auto">
          <FieldGroup className="space-y-8 pt-8">
            <div>
              <Field>
                <Label>Name</Label>
                <Input
                  {...register('name', {
                    required: 'Project name is required',
                  })}
                  invalid={!!errors.name}
                />
              </Field>
              {errors.name && <ErrorMessage className="mt-2">{errors.name.message}</ErrorMessage>}
            </div>
            <div>
              <Field>
                <Label>Description</Label>
                <Textarea
                  {...register('description', {
                    required: 'Description is required',
                  })}
                  rows={2}
                  invalid={!!errors.description}
                />
              </Field>
              {errors.description && (
                <ErrorMessage className="mt-2 pt-0">{errors.description.message}</ErrorMessage>
              )}
            </div>
          </FieldGroup>

          <FieldGroup className="mt-4 ">
            {Object.entries(templates).length > 1 && (
              <Field className="flex items-center justify-between">
                <Label>Pick a template for the project</Label>
                <Listbox
                  onChange={e => {
                    setSelectedTemplateId(e)
                  }}
                  value={selectedTemplateId}
                  className="max-w-96"
                >
                  {Object.entries(templates).map(([templateId, template]) => (
                    <ListboxOption key={templateId} value={templateId}>
                      <ListboxLabel>{template.name}</ListboxLabel>
                    </ListboxOption>
                  ))}
                </Listbox>
              </Field>
            )}
            {teams.length > 1 && (
              <Field className="flex items-center justify-between">
                <Label>Pick a team for the project</Label>
                <Listbox
                  onChange={e => {
                    setSelectedTeamId(e)
                  }}
                  value={selectedTeamId}
                  className="max-w-48"
                >
                  {teams.map(team => (
                    <ListboxOption key={team.id} value={team.id}>
                      <ListboxLabel>{team.name}</ListboxLabel>
                    </ListboxOption>
                  ))}
                </Listbox>
              </Field>
            )}
          </FieldGroup>

          <FieldGroup className="mt-24 space-y-8">
            <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
              Repository setup
            </Description>

            {repositoryFields.map((field, index) => (
              <div className=" sm:py-4" key={index}>
                <Field className="mt-2  sm:mt-0">
                  <Label>
                    <span>{field.name}</span>
                  </Label>
                  <Description className=" pb-2">{field.description}</Description>
                  {field.type === 'text-long' ? (
                    <Textarea
                      {...register(`repository.${index}.value`, {
                        required: field.required ? 'Field is required' : false,
                      })}
                      placeholder={field.placeholder}
                      textareaClassName={
                        field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                      }
                      invalid={!!errors.repository?.[index]?.value}
                    />
                  ) : (
                    <Input
                      {...register(`repository.${index}.value`, {
                        required: field.required ? 'Field is required' : false,
                      })}
                      placeholder={field.placeholder}
                      inputClassName={
                        field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                      }
                      invalid={!!errors.repository?.[index]?.value}
                    />
                  )}
                </Field>
                {errors.repository?.[index]?.value && (
                  <ErrorMessage className="mt-2 pt-0">
                    {errors.repository?.[index]?.value.message}
                  </ErrorMessage>
                )}
              </div>
            ))}
          </FieldGroup>

          <FieldGroup className="mt-24 space-y-8">
            <Description className="border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
              Environment setup
            </Description>

            {environmentFields.map((field, index) => (
              <div className="sm:py-4" key={field.key}>
                <Field className="mt-2  sm:mt-0">
                  <Label>{field.name}</Label>
                  <Description className="mb-4">{field.description}</Description>
                  <div className="grid w-full grid-cols-12">
                    <div className="col-span-5 h-full">
                      <div className="flex h-full flex-1 items-center justify-start font-mono">
                        {field.key}
                      </div>
                    </div>
                    <div className="col-span-7 flex h-full w-full flex-1 items-start">
                      {field.type === 'text-long' ? (
                        <Textarea
                          {...register(`environment.${index}.value`, {
                            required: field.required ? 'Field is required' : false,
                          })}
                          placeholder={field.placeholder}
                          textareaClassName={
                            field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                          }
                          invalid={!!errors.environment?.[index]?.value}
                        />
                      ) : (
                        <Input
                          {...register(`environment.${index}.value`, {
                            required: field.required ? 'Field is required' : false,
                          })}
                          placeholder={field.placeholder}
                          inputClassName={
                            field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                          }
                          className="h-full "
                          invalid={!!errors.environment?.[index]?.value}
                        />
                      )}
                    </div>
                  </div>
                </Field>
                {errors.environment?.[index]?.value && (
                  <ErrorMessage className="mt-2 pt-0">
                    {errors.environment?.[index]?.value.message}
                  </ErrorMessage>
                )}
              </div>
            ))}
          </FieldGroup>
        </div>
      </DialogBody>
      <DialogActions>
        <Button disabled={isUpdating} plain onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={isUpdating} className={isUpdating ? 'bg-zinc-600' : ''} type="submit">
          {isUpdating ? 'Working...' : 'Update project'}
        </Button>
      </DialogActions>
    </form>
  )
}

MUpdateProjectForm.propTypes = {
  templates: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  iterationDefault: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
}

export default function MUpdateProjectDialog({
  project,
  isOpen,
  onClose,
  onCompleted = data => {
    console.log('default function called on MUpdateProjectDialog with data: ', data)
  },
}) {
  const [iterationDefault, setIterationDefault] = useState(null)
  const [isIterationDefaultLoading, setIsIterationDefaultLoading] = useState(true)
  const { toast } = useToast()

  const { getTeamsByOrgId, getOrgById } = useStore(state => ({
    getTeamsByOrgId: state.getTeamsByOrgId,
    getOrgById: state.getOrgById,
  }))

  const organizationForProject = useMemo(() => {
    return getOrgById(project.organizationId)
  }, [getOrgById, project.organizationId])

  const teamsForOrganization = useMemo(() => {
    return getTeamsByOrgId(project.organizationId)
  }, [getTeamsByOrgId, project.organizationId])

  useEffect(() => {
    if (project?.id) {
      setIsIterationDefaultLoading(true)
      getIterationDefault({ projectId: project.id })
        .then(iterationData => {
          setIterationDefault(iterationData.data)
          setIsIterationDefaultLoading(false)
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIsIterationDefaultLoading(false)
          toast({
            variant: 'destructive',
            title: 'Error loading iteration defaults for project 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [project?.id])

  const loading = !teamsForOrganization || !organizationForProject || isIterationDefaultLoading

  return (
    <Dialog size="4xl" open={isOpen} onClose={onClose}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <MUpdateProjectForm
          project={project}
          onClose={onClose}
          onCompleted={onCompleted}
          teams={teamsForOrganization}
          templates={organizationForProject.templates}
          iterationDefault={iterationDefault}
        />
      )}
    </Dialog>
  )
}

MUpdateProjectDialog.propTypes = {
  project: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
}
