import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { updateOrganization } from '@/services/Firebase.js'
import { useToast } from '@/components/ui/use-toast.js'
import * as PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components/catalyst/dialog.jsx'
import { Button } from '@/components/catalyst/button.jsx'
import MOrganizationForm from '@/components/molecules/MOrganizationForm.jsx'

function useUpdate() {
  const [isUpdating, setIsUpdating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Update an organization.
   * @param {Object} payload - The contents of the organization.
   * @returns {Promise<Object>} A promise that resolves when the organization is updated.
   */
  const updateFn = async (organizationId, data) => {
    if (isUpdating) {
      return
    }
    setIsUpdating(true)
    setError(null)
    try {
      const payload = {
        organizationId,
        data,
      }
      const response = await updateOrganization(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  function resetUpdateFn() {
    setIsUpdating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isUpdating,
    error,
    updateFn,
    resetUpdateFn,
  }
}

export default function MUpdateOrganizationDialog({
  open,
  onClose,
  initialValues,
  onUpdated = () => {
    console.log('Default function called on MUpdateOrganizationDialog')
  },
}) {
  const { response, updateFn, error: updateError, isUpdating, resetUpdateFn } = useUpdate()

  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      organization: initialValues,
    }
  }, [initialValues])

  const methods = useForm({
    defaultValues: defaultValues,
  })
  const { handleSubmit, reset: resetForm } = methods

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post organization update / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Organization updated successfully! 🎉',
        description: '',
      })
      onUpdated(response?.data || {})
      resetUpdateFn()
      handleClose()
    }
    if (updateError) {
      toast({
        variant: 'destructive',
        title: 'Error updating organization 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error updating organization:', updateError)
      resetUpdateFn()
    }
  }, [response, updateError, toast, resetUpdateFn, handleClose, onUpdated])

  const onSubmit = useCallback(
    data => {
      if (!isUpdating) {
        updateFn(initialValues.id, {
          name: data?.organization.name,
          description: data?.organization.description,
        })
      }
    },
    [updateFn, isUpdating, initialValues.id]
  )

  return (
    <Dialog size="3xl" open={open} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Update Organization</DialogTitle>
          <DialogBody>
            <MOrganizationForm />
          </DialogBody>
          <DialogActions>
            <Button disabled={isUpdating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isUpdating} className={isUpdating ? 'bg-zinc-600' : ''} type="submit">
              {isUpdating ? 'Working...' : 'Update Organization'}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

MUpdateOrganizationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onUpdated: PropTypes.func,
}
