/**
 * Gets badge color for a given language code
 * @param {string} languageCode - javascript, python, json, markdown
 * @returns {string} - Color for the badge
 */
export function getBadgeColorForContentType(contentType) {
  switch (contentType) {
    case 'json':
      return 'zinc'
    case 'python':
      return 'emerald'
    case 'javascript':
      return 'amber'
    case 'typescript':
      return 'yellow'
    default:
      return 'zinc'
  }
}

/**
 * Gets the label and color for the scope level of a hint
 * @param {{organization_id: string?, project_id: string?, iteration_id: string?}} hint - The hint object
 * @returns {[string, string]} - [label, color] The label and the color for the badge
 */
export function getHintScopeLevelLabel(hint) {
  if (hint?.iteration_id) {
    return ['iteration', 'yellow']
  }
  if (hint?.project_id) {
    return ['project', 'green']
  }
  if (hint?.organization_id) {
    return ['organization', 'cyan']
  }
  return ['global', 'zinc']
}

/**
 * Gets the available hint content types
 * @returns {string[]} - The available content types
 */
export function getAvailableHintsProgrammingLanguages() {
  return ['python', 'markdown', 'javascript', 'typescript', 'shell', 'json', 'go', 'java']
}

/**
 * Gets the available hint role types
 * @returns {string[]} - The available role types
 */

export function getAvailableHintRoleTypes() {
  return [
    'high_level_planner',
    'element_planner',
    'researcher',
    'implementer',
    'troubleshooter',
    'documenter',
  ]
}

/**
 * Generate a JSON object for a hint to be saved over Hitns Service API
 * @param {{title: string, context: string?, roleTypes: string[]?, libraries: string[]?, services: string[]?, code: string, contentType: string, languages: string[]?}} hint - The hint object
 * @returns {{title: string, context: string, role_types: string[], libraries: string[], services: string[], code: string, content_type: string, languages: string[]}} - The JSON object to be saved
 */
export function getHintJsonForHintsService({
  title,
  context,
  roleTypes,
  libraries,
  services,
  code,
  contentType,
  languages,
}) {
  let contextToBeSaved = context
  if (!context || context === '') {
    contextToBeSaved = title
  }
  return {
    title,
    context: contextToBeSaved,
    role_types: roleTypes,
    libraries,
    services,
    code,
    content_type: contentType,
    languages,
  }
}

/**
 * Generate a JSON object for a hint to be saved over Hitns Service API
 * @param {object} [param0] - The org & project
 * @param {string} param0.organization_id - The organization id
 * @param {string} [param0.project_id] - The project id
 * @param {string[]} [param0.libraries] - The libraries
 * @param {string[]} [param0.services] - The services
 * @returns {{title: string, context: string, role_types: string[], libraries: string[], services: string[], code: string, content_type: string, languages: string[]}} - The JSON object to be saved
 */
export function getTemplatedHint({
  organization_id = null,
  project_id = null,
  libraries = [],
  services = [],
}) {
  return {
    id: null,
    title: '',
    content_type: 'markdown',
    role_types: [],
    organization_id: organization_id,
    project_id: project_id,
    languages: [],
    services: services,
    libraries: libraries,
    context: null,
  }
}
