import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function AActionContentFiles({ content, terminalOutputs }) {
  const className = classNames(
    'bg-zinc-600 text-zinc-100 p-2 rounded-t-sm mt-1 break-all',
    terminalOutputs && 'cursor-pointer border-b border-dashed border-zinc-200 ',
    !terminalOutputs && 'rounded-b-sm'
  )
  return <div className={className}>{content}</div>
}

AActionContentFiles.propTypes = {
  content: PropTypes.string,
  terminalOutputs: PropTypes.arrayOf(PropTypes.string),
}
