import * as PropTypes from 'prop-types'

export function CreateButton(props) {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className="relative inline-flex items-center rounded-md bg-mimirBlue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-mimirBlue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mimirBlue-500"
    >
      {props.label}
    </button>
  )
}

CreateButton.propTypes = { onClick: PropTypes.func, label: PropTypes.string }
