import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentUserSuggestion from '@/components/actions/components/AActionContentUserSuggestion.jsx'

export default function UserSuggestionAction({ action, isLast = false }) {
  return (
    <GenericAction
      action={action}
      detailsContent={`## User suggestion:\n\n${action?.args?.text}\n\n`}
      actionIcon={ChatBubbleBottomCenterTextIconSolid}
      isLast={isLast}
      customOptions={{ iconStyle: 'text-amber-400', actionBodyStyle: 'bg-amber-50 text-red-500' }}
    >
      <AContentTitle action={action} title="User suggestion" />
      <AActionContentGenericLayout>
        <AActionContentUserSuggestion content={`! ` + action?.args?.text} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

UserSuggestionAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
