import PropTypes from 'prop-types'
import MActionListItem from '@/components/molecules/iteration-details/MActionListItem.jsx'
import { actionShape } from '@/components/propTypes.js'
import { useSearchParams } from 'react-router-dom'
import { safeParseInt } from '@/lib/params-utils.js'
import { useEffect, useRef } from 'react'

/** @typedef {{
   content: string,
   title: string,
   detailsContent: string,
 }} ActionResultsDisplay */

export default function MActionsList({ actions = [] }) {
  const [searchParams] = useSearchParams()
  const selectedActionIndex = safeParseInt(searchParams.get('action'))
  const listRef = useRef(null)

  useEffect(() => {
    if (selectedActionIndex !== null && listRef.current) {
      const selectedElement = listRef.current.querySelector(`[data-index="${selectedActionIndex}"]`)
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [])
  return (
    <ul ref={listRef} role="list" className="space-y-6">
      {actions?.map((action, index) => (
        <MActionListItem
          key={`${action.actionId}-${action?.outputs?.length || 0}`}
          action={action}
          isLast={index === actions.length - 1}
        />
      ))}
    </ul>
  )
}

MActionsList.propTypes = {
  //make array of action shapes
  actions: PropTypes.arrayOf(actionShape),
}
