import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'

import MIterationsPicker from '@/components/molecules/project-details/MIterationsPicker'
import OIterationDetails from '@/components/organisms/project-details/OIterationDetails'

import useStore from '@/stores/useStore'

export default function OIterationsListAndDetails({ iterations, iterationsLoading, lastTick }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const setActiveIterationStatus = useStore(state => state.setActiveIterationStatus)

  const iterationId = useMemo(() => {
    return searchParams.get('iteration')
  }, [searchParams])

  const [activeIterationId, setActiveIterationId] = useState(iterationId)

  // Set active iteration id from URL
  useEffect(() => {
    setActiveIterationId(iterationId)
  }, [iterationId])

  const activeIterationMeta = useMemo(() => {
    if (!activeIterationId || !iterations) {
      return null
    }
    return iterations.find(iteration => iteration.id === activeIterationId)
  }, [activeIterationId, iterations])

  const handleActiveIterationSelected = useCallback(
    selectedIterationId => {
      setSearchParams({ iteration: selectedIterationId }, { replace: true })
      setActiveIterationId(selectedIterationId)
    },
    [setSearchParams]
  )

  useEffect(() => {
    if (!activeIterationId && iterations?.length > 0) {
      handleActiveIterationSelected(iterations?.[0]?.id)
    }
  }, [iterations, activeIterationId, handleActiveIterationSelected])

  useEffect(() => {
    if (activeIterationMeta && activeIterationMeta?.status) {
      setActiveIterationStatus(activeIterationMeta.status)
    } else {
      setActiveIterationStatus(null)
    }
    return () => setActiveIterationStatus(null)
  }, [activeIterationMeta, setActiveIterationStatus])

  const isActiveIterationLatestIteration = useMemo(() => {
    if (iterations?.length > 0) {
      return activeIterationId === iterations?.[0]?.id
    }
    return false
  }, [iterations, activeIterationId])

  const handleIterationCreated = useCallback(
    data => {
      handleActiveIterationSelected(data.iterationId)
    },
    [handleActiveIterationSelected]
  )

  return (
    <div className="mx-auto flex w-full items-start pt-4">
      <main className="w-3/4 flex-1 ">
        <MIterationsPicker
          iterations={iterations}
          isLoading={iterationsLoading}
          activeIterationId={activeIterationId}
          onActiveSelected={handleActiveIterationSelected}
          onIterationCreated={handleIterationCreated}
        />
        {!!activeIterationMeta && (
          <OIterationDetails
            iterationMeta={activeIterationMeta}
            isNewestIteration={isActiveIterationLatestIteration}
            lastTick={lastTick}
            onIterationCreated={handleIterationCreated}
          />
        )}
      </main>
    </div>
  )
}

OIterationsListAndDetails.propTypes = {
  iterations: PropTypes.array,
  iterationsLoading: PropTypes.bool,
  lastTick: PropTypes.number,
}
