import PropTypes from 'prop-types'

export default function AActionContentGenericLayout({ children }) {
  return (
    <div className="line-clamp-6 flex max-w-full flex-col pt-1 font-mono font-normal text-zinc-700">
      {children}
    </div>
  )
}

AActionContentGenericLayout.propTypes = { children: PropTypes.node }
