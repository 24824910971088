import { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { UserGroupIcon } from '@heroicons/react/20/solid'

import { Badge } from '@/components/catalyst/badge'

import { getHintScopeLevelLabel } from '@/lib/hints-helpers'
import { Button } from '@/components/catalyst/button'

function MHintsLoadingSkeleton({ howMany = 8 }) {
  return (
    <div className="mt-8 flow-root">
      <div className="overflow-x-auto ">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="w-3/12 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Roles
                </th>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Services
                </th>
                <th
                  scope="col"
                  className="w-3/12 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Libraries
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Scope
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {Array.from({ length: howMany })?.map((_hint, index) => (
                <tr key={index} className="divide-x divide-gray-200">
                  <td className="whitespace-normal py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                    <div className="h-[1em] w-48 animate-pulse rounded-full bg-zinc-200" />
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    <div className="mb-2 flex rounded-sm bg-zinc-100 p-2">
                      <UserGroupIcon className="mr-2 h-4 w-4" />

                      <div className="block text-xs text-zinc-800">
                        <div className="h-[1em] w-24 animate-pulse rounded-full bg-zinc-200" />
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    <Badge color="zinc" className="mb-2 inline-block animate-pulse">
                      <div className="rounded-ful h-[1em] w-24 animate-pulse" />
                    </Badge>
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    <Badge color="zinc" className="mb-2 mr-2 inline-block animate-pulse">
                      <div className="rounded-ful h-[1em] w-24 animate-pulse" />
                    </Badge>
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    <Badge color="zinc" className="mb-2 mr-2 inline-block animate-pulse">
                      <div className="rounded-ful h-[1em] w-16 animate-pulse" />
                    </Badge>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                    <Button className="h-[2em] cursor-wait" disabled outline>
                      <div className="rounded-ful h-[1em] w-12 animate-pulse rounded-full bg-zinc-200" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

MHintsLoadingSkeleton.propTypes = {
  howMany: PropTypes.number,
}

export default function MHintsList({
  hints,
  activeHintId,
  onActiveSelected = hintId => {
    console.log('Selected hint', hintId)
  },
  isHintCreating = false,
  isLoading = false,
}) {
  const labelsAndColorsForScopeLevels = useMemo(() => {
    if (!hints || !hints.length) return {}
    return hints?.reduce((acc, hint) => {
      const [label, color, IconComponent] = getHintScopeLevelLabel(hint)
      acc[hint.id] = { label, color, IconComponent }
      return acc
    }, {})
  }, [hints])

  const sortedHints = useMemo(() => {
    // first sort by: hint.iteration_id if exists, should be on top
    // second sort byhint.project_id if exists , may be null, should be on top
    // third sort by hint.organization_id if exists, may be null, should be on top
    // last sort by hint.updated_at
    // chain those sortings to get the correct order
    // suggest code
    return hints?.sort((a, b) => {
      if (a.iteration_id && !b.iteration_id) return -1
      if (b.iteration_id && !a.iteration_id) return 1
      if (a.project_id && !b.project_id) return -1
      if (b.project_id && !a.project_id) return 1
      if (a.organization_id && !b.organization_id) return -1
      if (b.organization_id && !a.organization_id) return 1
      return new Date(b.updated_at) - new Date(a.updated_at)
    })
  }, [hints])

  if (isLoading) {
    return <MHintsLoadingSkeleton howMany={8} />
  }

  return (
    <div className="mt-8 flow-root">
      <div className="overflow-x-auto ">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="w-3/12 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Roles
                </th>
                <th
                  scope="col"
                  className="w-2/12 px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Services
                </th>
                <th
                  scope="col"
                  className="w-3/12 py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Libraries
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Scope
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {sortedHints?.map(hint => (
                <tr key={hint.id} className="divide-x divide-gray-200">
                  <td
                    className={classNames(
                      'whitespace-normal py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0',
                      activeHintId === hint.id && 'font-extrabold'
                    )}
                  >
                    {hint.title}
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    {hint?.role_types?.map(role => (
                      <div key={role} className="mb-2 flex rounded-sm bg-zinc-100 p-2">
                        <UserGroupIcon className="mr-2 h-4 w-4" />

                        <div className="block text-xs text-zinc-800">{role}</div>
                      </div>
                    ))}
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    {hint?.services?.map(service => (
                      <Badge key={service} color="zinc" className="mb-2 inline-block">
                        {service}
                      </Badge>
                    ))}
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    {hint?.libraries?.map(library => (
                      <Badge key={library} color="zinc" className="mb-2 mr-2 inline-block">
                        {library}
                      </Badge>
                    ))}
                  </td>
                  <td className="whitespace-normal p-4 text-sm text-gray-500">
                    <Badge
                      color={labelsAndColorsForScopeLevels[hint.id]?.color}
                      className="min-w-18 capitalize"
                    >
                      {labelsAndColorsForScopeLevels[hint.id]?.label}
                    </Badge>
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
                    <Button
                      className="cursor-pointer"
                      outline
                      onClick={() => {
                        onActiveSelected(hint.id)
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

MHintsList.propTypes = {
  hints: PropTypes.array,
  activeHintId: PropTypes.string,
  onActiveSelected: PropTypes.func,
  isHintCreating: PropTypes.bool,
  lastTick: PropTypes.number,
  isLoading: PropTypes.bool,
}
