import { useMemo, useEffect, useRef } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Route, Routes, Navigate, ScrollRestoration, useLocation } from 'react-router-dom'
import useStore from '@/stores/useStore'

import { auth } from '@/services/Firebase'
import { ROUTES } from '@/const/routes'

import PLoginWithEmailLinkConfirmation from '@/components/pages/PLoginWithEmailLinkConfirmation'
import PLogin from '@/components/pages/PLogin'

import PHome from '@/components/pages/PHome'
import PRojectDetails from '@/components/pages/PProjectDetails'
import POrganizationDetails from '@/components/pages/POrganizationDetails'
import PTeamDetails from '@/components/pages/PTeamDetails'
import PManageVMs from '@/components/pages/PManageVMs'
import PIterationFeedback from './components/pages/PIterationFeedback'
import {
  analyticsTrackEvent,
  ANALYTIC_EVENTS,
  analyticsOpInCapturing,
  analyticsOptOutCapturing,
  analyticsIdentify,
  analyticsIsOptedInCapturing,
} from './services/Analytics'

function LoginRouter() {
  return (
    <>
      <Routes>
        <Route path={ROUTES.ROOT} element={<PLogin />} />
        <Route
          exact
          path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION}
          element={<PLoginWithEmailLinkConfirmation />}
        />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <ScrollRestoration />
    </>
  )
}

function MMainAnalytics() {
  const [authUser] = useAuthState(auth)
  const appOpenEventSent = useRef(false)
  const organizations = useStore(state => state.organizations)
  const user = useStore(state => state.user)
  const userRoles = useStore(state => state.userRoles)
  const location = useLocation()

  useEffect(() => {
    if (authUser?.uid && user && organizations.length > 0 && userRoles) {
      const isExternal = user?.isExternal ? true : false

      // if user is external, we need to track the user
      // if user is internal, we need to opt out of tracking
      if (isExternal) {
        if (!analyticsIsOptedInCapturing()) {
          analyticsOpInCapturing()
        }

        const orgIds = organizations.map(org => org.id)
        analyticsIdentify(authUser?.uid, {
          name: `${user?.firstName} ${user?.lastName}`,
          userId: authUser?.uid,
          isExternal,
          organizations: orgIds,
        })
        if (!appOpenEventSent.current) {
          analyticsTrackEvent(ANALYTIC_EVENTS.APP_OPEN)
          appOpenEventSent.current = true
        }
      } else {
        if (analyticsIsOptedInCapturing()) {
          analyticsOptOutCapturing()
        }
      }
    }
  }, [user, organizations, userRoles, authUser?.uid])

  useEffect(() => {
    analyticsTrackEvent('$pageview')
  }, [location])

  return null
}

function App() {
  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const loadInitialData = useStore(state => state.loadInitialData)

  const isUserLoggedOut = useMemo(() => {
    return Boolean(!authUser && !isAuthUserLoading)
  }, [authUser, isAuthUserLoading])

  // Load initial data when auth is ready and user is logged in
  // Unsubscribe from all listeners when component is unmounted
  useEffect(() => {
    if (authUser?.uid) {
      loadInitialData(authUser.uid)
    }
    return () => {
      useStore.getState().unsubscribeFromAllListeners()
    }
  }, [authUser?.uid, loadInitialData])

  if (isAuthUserLoading) {
    return null
  }

  if (isUserLoggedOut) {
    return <LoginRouter />
  }
  return (
    <>
      <Routes>
        <Route path={ROUTES.SIGN_IN_WITH_LINK_CONFIRMATION} element={<Navigate replace to="/" />} />
        <Route path={ROUTES.HOME} exact element={<PHome />} />
        <Route path={ROUTES.PROJECT_DETAILS} element={<PRojectDetails />} />
        <Route path={ROUTES.ORGANIZATION_DETAILS} element={<POrganizationDetails />} />
        <Route path={ROUTES.TEAM_DETAILS} element={<PTeamDetails />} />
        <Route path={ROUTES.MANAGE_VMS} element={<PManageVMs />} />
        <Route path={ROUTES.ITERATION_FEEDBACK} element={<PIterationFeedback />} />
        <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
      {/*<ScrollRestoration />*/}
      <MMainAnalytics />
    </>
  )
}

export default App
