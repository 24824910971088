import { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { Description, Field, Label } from '@/components/catalyst/fieldset'
import { Textarea } from '@/components/catalyst/textarea'

import { useToast } from '@/components/ui/use-toast'

import { createIterationForProject } from '@/services/Firebase'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const DEFAULT_PROMPT = "Describe what you'd like to accomplish in this iteration."

// if the current url starts with old. then don't start gunslinger for new iteration

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

function useCreateIteration() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new iteration for a project.
   * @param {Object} options - The options for the function.
   * @param {String} options.projectId - The ID of the project.
   * @param {String} options.prompt - The prompt for the iteration.
   
   * @returns {Promise<Object>} A promise that resolves when the iteration is created.
   */
  const createIteration = async ({ projectId, prompt, sourceIterationId }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createIterationForProject({
        projectId,
        prompt,
        sourceIterationId,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
      })
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateIteration() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createIterationFn: createIteration, resetCreateIteration }
}

/**
 * A dialog for creating a new iteration.
 * @param {Object} props - The props for the component.
 * @param {String} props.projectId - The ID of the project.
 * @param {Boolean} props.isOpen - Whether the dialog is open.
 * @param {Function} props.onClose - A function to close the dialog.
 * @param {Boolean} props.isFirstIterationForProject - Whether this is the first iteration for the project.
 * @param {Object} props.sourceIterationData - The data for the source iteration.
 * @param {Function} props.onIterationCreated - A function to call when the iteration is created.
 * @returns {JSX.Element} The JSX element for the component.
 *
 */
export default function MNewIterationDialog({
  projectId,
  isOpen,
  onClose,
  isFirstIterationForProject = false,
  sourceIterationData = {},
  onIterationCreated = () => {
    console.log('Default function called on MNewIterationDialog')
  },
}) {
  const {
    response,
    createIterationFn,
    error: iterationCreationError,
    isCreating,
    resetCreateIteration: resetCreateIterationFn,
  } = useCreateIteration()

  const { toast } = useToast()

  const sourceIterationId = useMemo(() => {
    return sourceIterationData?.id || null
  }, [sourceIterationData])

  const defaultValues = useMemo(() => {
    return {
      prompt: sourceIterationData?.prompt || DEFAULT_PROMPT,
    }
  }, [sourceIterationData])

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: defaultValues,
  })

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post iteration creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Iteration created successfully! 🎉',
        description:
          'The iteration will start running soon. You can check its status in the project dashboard.',
      })
      onIterationCreated(response?.data || {})
      resetCreateIterationFn()
      handleClose()
    }
    if (iterationCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating iteration 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating iteration:', iterationCreationError)
      resetCreateIterationFn()
    }
  }, [
    response,
    iterationCreationError,
    toast,
    resetCreateIterationFn,
    handleClose,
    onIterationCreated,
  ])

  // prepare form data for iteration to be created
  const onSubmit = useCallback(
    data => {
      if (projectId && !isCreating) {
        createIterationFn({ projectId, prompt: data?.prompt, sourceIterationId })
      }
    },
    [projectId, createIterationFn, isCreating, sourceIterationId]
  )

  // different labels for first iteration
  const dialogLabels = useMemo(() => {
    let labels = {
      title: 'Next iteration',
      description: 'This iteration will use code from the selected iteration as a starting point.',
      instructions: 'Instructions',
      instructionsDescription: 'Only include changes you want to make vs current iteration',
    }
    if (isFirstIterationForProject) {
      labels = {
        title: 'First iteration',
        description: 'This is the first iteration for the project.',
        instructions: 'Instructions',
        instructionsDescription: `Describe what you'd like to accomplish in this iteration. `,
      }
    }
    return labels
  }, [isFirstIterationForProject])

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{dialogLabels.title}</DialogTitle>
          <DialogDescription>{dialogLabels.description}</DialogDescription>
          <DialogBody>
            <div className="space-y-12 sm:space-y-16">
              <Field>
                <Label>{dialogLabels.instructions}</Label>
                <Description>{dialogLabels.instructionsDescription}</Description>
                <Textarea {...register('prompt', { required: true })} rows={10} />
              </Field>
            </div>
          </DialogBody>
          <DialogActions>
            <Button disabled={isCreating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isCreating} className={isCreating ? 'bg-zinc-600' : ''} type="submit">
              {isCreating ? 'Working...' : 'Create iteration'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

MNewIterationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFirstIterationForProject: PropTypes.bool,
  environment: PropTypes.object,
  sourceIterationData: PropTypes.object,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string,
  onIterationCreated: PropTypes.func,
}
