import { ACTION_STATUSES } from '@/const/const.js'
import { CheckCircleIcon } from '@heroicons/react/24/solid/index.js'
import PropTypes from 'prop-types'

function ActionStatus({ status }) {
  return (
    <div className="relative flex h-6 w-6 flex-none items-center justify-center place-self-start bg-transparent">
      {status === ACTION_STATUSES.DONE && (
        <CheckCircleIcon
          className="h-4 w-4 self-start rounded-full bg-gray-50 text-emerald-500"
          aria-hidden="true"
        />
      )}
      {status === ACTION_STATUSES.RUNNING && (
        <div className="h-3 w-3 animate-pulse self-start rounded-full bg-sky-400 ring-1 ring-gray-300 " />
      )}
      {status === ACTION_STATUSES.AWAITING && (
        <div className="h-3 w-3 self-start rounded-full bg-zinc-400 ring-1 ring-gray-300 " />
      )}
    </div>
  )
}

ActionStatus.propTypes = {
  status: PropTypes.oneOf([ACTION_STATUSES.DONE, ACTION_STATUSES.RUNNING, ACTION_STATUSES.AWAITING])
    .isRequired,
}

export default ActionStatus
