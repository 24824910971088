import { useCallback, useState } from 'react'

export function useMutation({ mutationCallback }) {
  const [isMutating, setIsMutating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const mutationFn = async payload => {
    if (isMutating) {
      return
    }
    setIsMutating(true)
    setError(null)
    try {
      const response = await mutationCallback(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsMutating(false)
    }
  }

  function resetFn() {
    setIsMutating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isMutating,
    error,
    mutationFn,
    resetFn,
  }
}

export function useClipboardCopy() {
  const [error, setError] = useState(null)

  const copy = useCallback((text, onSuccess, onError) => {
    if (!navigator.clipboard) {
      const errorMessage = 'Clipboard API not supported'
      setError(errorMessage)
      if (onError) onError(errorMessage)
      return
    }

    navigator.clipboard
      .writeText(text)
      .then(() => {
        setError(null)
        if (onSuccess) onSuccess()
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
        const errorMessage = 'Failed to copy'
        setError(errorMessage)
        if (onError) onError(errorMessage)
      })
  }, [])

  return { error, copy }
}
