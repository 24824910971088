import PropTypes from 'prop-types'

import OHintEditorDialog from '@/components/organisms/project-details/OHintEditorDialog'

/**
 * @returns {JSX.Element}
 */
export default function OHintDetails({
  hint,
  scopeOrganizationId,
  scopeProjectId,
  onHintUpdated,
  onHintDeleted,
  onCanceled,
}) {
  return (
    <>
      <OHintEditorDialog
        key={hint.id}
        isOpen
        onClose={onCanceled}
        hint={hint}
        scopeOrganizationId={scopeOrganizationId}
        scopeProjectId={scopeProjectId}
        onSaveFinished={onHintUpdated}
        onDeleteFinished={onHintDeleted}
      />
    </>
  )
}

OHintDetails.propTypes = {
  // figure out the shape of the hint object

  hint: PropTypes.shape({
    id: PropTypes.string,
    organization_id: PropTypes.string,
    project_id: PropTypes.string,
    iteration_id: PropTypes.string,
    title: PropTypes.string,
    content_type: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    services: PropTypes.arrayOf(PropTypes.string),
    libraries: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    context: PropTypes.string,
    role_types: PropTypes.arrayOf(PropTypes.string),
  }),
  scopeOrganizationId: PropTypes.string.isRequired,
  scopeProjectId: PropTypes.string.isRequired,
  onHintUpdated: PropTypes.func,
  onHintDeleted: PropTypes.func,
  onCanceled: PropTypes.func,
}
