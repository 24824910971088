import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { GlobeAltIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function BrowserAction({ action, isLast = false }) {
  const comment = action?.args?.comment
  const url = action?.args?.url
  let detailsContent = ''

  detailsContent += `## ${comment}\n\nURL: ${url}\n\n\n`
  const outputs = action?.outputs
  if (outputs) {
    detailsContent += `## OUTPUTS:\n`
    outputs.forEach(output => {
      Object.entries(output).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          detailsContent += `* ${key.toUpperCase()}:\n`
          value.forEach((val, i) => {
            if (typeof val === 'object') {
              const jsonedVal = JSON.stringify(val, null, 2)
              detailsContent += `* * ${i + 1}:\n` + '```json\n' + jsonedVal + '\n```\n\n'
            }
          })
        } else if (value === null) {
          detailsContent += `* ${key.toUpperCase()}: NULL\n\n`
        } else if (typeof value === 'object') {
          const jsonedVal = JSON.stringify(value, null, 2)
          detailsContent += `* ${key.toUpperCase()}:\n` + '```json\n' + jsonedVal + '\n' + '```\n\n'
        } else if (typeof value === 'string') {
          detailsContent += `* ${key.toUpperCase()}:\n* * ` + value + '\n\n'
        }
      })
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={GlobeAltIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Browser testing" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={comment} />
        <AActionContentTerminal content={'$ ' + url} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

BrowserAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
