import {
  Popover,
  PopoverArrow,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover.jsx'
import { XMarkIcon } from '@heroicons/react/16/solid/index.js'
import PropTypes from 'prop-types'
import { colorDictShape } from '@/components/propTypes.js'

export function MExtractedColorsPreview({ colors }) {
  return (
    <div className="flex flex-col gap-x-2 py-2">
      <div className="flex gap-x-2">
        {colors?.map((colorDict, index) => {
          return (
            <div key={index} className="">
              <Popover defaultOpen={false}>
                <PopoverTrigger asChild>
                  <div
                    className="flex h-8 w-8 flex-col gap-y-1 rounded-sm border border-zinc-800 shadow-sm"
                    style={{ backgroundColor: colorDict?.color }}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <div>
                    <div className="mb-2 flex flex-row justify-between">
                      <div className="py-2 font-mono">{colorDict?.color || 'No color'}</div>
                      <PopoverClose aria-label="Close color details">
                        <XMarkIcon className="h-5 w-5" />
                      </PopoverClose>
                    </div>
                    <p className="text-sm text-zinc-500">
                      <div
                        style={{ backgroundColor: colorDict?.color }}
                        className="h-12 flex-1 rounded-sm border border-zinc-800 shadow-sm"
                      />
                      <div className="py-2">{colorDict?.description}</div>
                    </p>
                  </div>
                  <PopoverArrow />
                </PopoverContent>
              </Popover>
            </div>
          )
        })}
      </div>
    </div>
  )
}

MExtractedColorsPreview.propTypes = {
  colors: PropTypes.arrayOf(colorDictShape),
}
