import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { DocumentPlusIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentFiles from '@/components/actions/components/AActionContentFiles.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'

export default function PreciselyEditFileAction({ action, isLast = false }) {
  const filePath = action?.args?.path
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${action?.args?.comment}\n\n## EDIT INSTRUCTIONS:\n${action?.args?.edit_instructions}`

  if (action.outputs) {
    detailsContent += '## OUTPUTS:\n'
    action?.outputs?.forEach(output => {
      // check if output is an object
      if (output && typeof output === 'object') {
        const outputEntries = Object.entries(output).map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `${entry}`
        })
      }
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={DocumentPlusIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Edit file" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentFiles content={'> ' + action?.args?.path} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

PreciselyEditFileAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
