import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import CodeMirror from '@uiw/react-codemirror'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { languages } from '@codemirror/language-data'
import { python } from '@codemirror/lang-python'
import { javascript } from '@codemirror/lang-javascript'
import { monokai } from '@uiw/codemirror-theme-monokai'
import { json } from '@codemirror/lang-json'
import { EditorView } from '@codemirror/view'

/**
 * Gets CodeMirror extensions for a given language code
 * @param {string} landuageCode - javascript, python, json, markdown
 * @returns {object[]} - Array of CodeMirror extensions
 */
function getExtenstionsForLanguageCode(landuageCode) {
  switch (landuageCode) {
    case 'json':
      return [json(), EditorView.lineWrapping]
    case 'python':
      return [python()]
    case 'javascript':
      return [javascript({ jsx: true }), EditorView.lineWrapping]
    case 'typescript':
      return [javascript({ typescript: true, jsx: true }), EditorView.lineWrapping]
    default:
      return [
        markdown({ base: markdownLanguage, codeLanguages: languages }),
        EditorView.lineWrapping,
      ]
  }
}

/**
 * Code Preview element, supports languages Markdown and JSON
 * @param {object} props - The props object
 * @param {string | object} props.code - code in json or text format
 * @param {string} props.language - language of the code, default is markdown
 * @param {boolean} [props.editable] - If the editor should allow editing of the code
 * @param {function} props.onCodeChange - Callback function to be called when the code is changed
 * @returns {JSX.Element | null}
 */
export default function OCodeEditor({
  code = null,
  language = 'markdown',
  editable = false,
  onCodeChange = () => {
    console.log('DEFAULT onCodeChange called from OCodeEditor')
  },
}) {
  let convertedCode = useMemo(() => {
    if (!code) {
      return ''
    } else if (typeof code === 'object') {
      return JSON.stringify(code, null, 2)
    } else {
      return code
    }
  }, [code])

  const extensions = getExtenstionsForLanguageCode(language)

  const handleOnChange = useCallback(
    value => {
      onCodeChange(value)
    },
    [onCodeChange]
  )

  return (
    <div className="overflow-clip whitespace-normal rounded-xl">
      <CodeMirror
        theme={monokai}
        editable={editable}
        value={convertedCode}
        extensions={extensions}
        onChange={handleOnChange}
      />
    </div>
  )
}

OCodeEditor.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  language: PropTypes.string,
  editable: PropTypes.bool,
  onCodeChange: PropTypes.func,
}
