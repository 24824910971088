import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { DocumentPlusIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentFiles from '@/components/actions/components/AActionContentFiles.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'

export default function UpdateEnvVariableAction({ action, isLast = false }) {
  const args = action?.args ?? {}
  const filePath = args.path
  const content = `> append ${args.dotenv_var_name} > ${filePath}`
  let detailsContent = `## PATH:\n${filePath}\n\n## COMMENT:\n${args.comment}\n\n`

  detailsContent +=
    '## UPDATE ENV VARIABLE:\n' + args.dotenv_var_name + ': ' + hideMiddle(args.var_value) + '\n\n'
  detailsContent += '## OUTPUTS:\n'
  action?.outputs?.forEach(output => {
    Object.entries(output).forEach(([key, value]) => {
      detailsContent += `${key}: ${value}\n`
    })
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={DocumentPlusIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Update env variable" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentFiles content={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

UpdateEnvVariableAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}

function hideMiddle(envVar) {
  const VISIBLE_CHARS = 3
  const length = envVar.length

  if (length <= 6) {
    // If the string is too short, just return it as is (optional, based on your requirements)
    return envVar
  }

  const start = envVar.slice(0, VISIBLE_CHARS)
  const end = envVar.slice(-VISIBLE_CHARS)
  const hiddenLength = length - VISIBLE_CHARS * 2
  const hidden = '*'.repeat(hiddenLength)

  return `${start}${hidden}${end}`
}
