import { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useForm, useWatch, Controller } from 'react-hook-form'

import { XMarkIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'

import { Dialog, DialogActions, DialogBody } from '@/components/catalyst/dialog'

import { Field, FieldGroup, Fieldset, Description } from '@/components/catalyst/fieldset'
import { Label } from '@/components/catalyst/fieldset'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox'
import { Input } from '@/components/catalyst/input'
import { Textarea } from '@/components/catalyst/textarea'

import OCodeEditor from '@/components/organisms/OCodeEditor'
import {
  updateHintFirebaseFunction,
  createHintFirebaseFunction,
  deleteHintFirebaseFunction,
} from '@/services/Firebase'
import { useToast } from '@/components/ui/use-toast'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
  PopoverArrow,
} from '@/components/ui/popover'

import {
  MFormContentTypeEditor,
  MFormLanguagesEditor,
  MFormLibrariesEditor,
  MFormRoleTypesEditor,
  MFormServicesEditor,
} from '@/components/organisms/project-details/OHintEditorDialogComponents'

import {
  getAvailableHintsProgrammingLanguages,
  getAvailableHintRoleTypes,
} from '@/lib/hints-helpers'

const ALL_LANGUAGES = getAvailableHintsProgrammingLanguages()
const ALL_ROLE_TYPES = getAvailableHintRoleTypes()

const HINT_SCOPE_LABELS = {
  PROJECT: 'Current project',
  ORGANIZATION: 'Current organization',
  GLOBAL: 'Global',
}

function getHintScopeNameForHint({ organizationId, projectId }) {
  if (projectId) {
    return HINT_SCOPE_LABELS.PROJECT
  }
  if (organizationId) {
    return HINT_SCOPE_LABELS.ORGANIZATION
  }
  return HINT_SCOPE_LABELS.GLOBAL
}

function getAllHintScopeNames() {
  return Object.values(HINT_SCOPE_LABELS)
}

function preventEnterKeySubmission(e) {
  const target = e.target
  if (e.key === 'Enter' && target instanceof HTMLInputElement) {
    e.preventDefault()
  }
}

export default function OHintEditorDialog({
  isOpen = false,
  onClose = () => {},
  hint,
  scopeOrganizationId,
  scopeProjectId,
  onSaveFinished = hintObj => {
    console.log('DEFAULT onSaveFinished', hintObj)
  },
  onDeleteFinished = hintId => {
    console.log('DEFAULT onDeleteFinished', hintId)
  },
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const isWorking = useMemo(() => isSaving || isDeleting, [isSaving, isDeleting])
  const { toast } = useToast()
  const hintWithInitialValues = useMemo(() => {
    return {
      ...hint,
      services: hint.services || [],
      libraries: hint.libraries || [],
      role_types: hint.role_types || [],
      languages: hint.languages || [],
    }
  }, [hint])

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    reset: resetForm,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      ...hintWithInitialValues,
    },
  })

  const currentContentType = useWatch({
    control,
    name: 'content_type',
    defaultValue: hint.content_type,
  })
  const currentLanguages = useWatch({ control, name: 'languages', defaultValue: hint.languages })
  const currentRoleTypes = useWatch({ control, name: 'role_types', defaultValue: hint.role_types })
  const currentOrganizationId = useWatch({
    control,
    name: 'organization_id',
    defaultValue: hint.organization_id,
  })
  const currentProjectId = useWatch({ control, name: 'project_id', defaultValue: hint.project_id })
  const currentIterationId = useWatch({
    control,
    name: 'iteration_id',
    defaultValue: hint.iteration_id,
  })

  const hintScope = useMemo(() => {
    return getHintScopeNameForHint({
      organizationId: currentOrganizationId,
      projectId: currentProjectId,
      iterationId: currentIterationId,
    })
  }, [currentOrganizationId, currentProjectId, currentIterationId])

  const updateHintScope = useCallback(
    newScope => {
      // if scope changed to PROJECT set org to scoped org and project to scoped project
      // if scope changed to ORGANIZATION set org to scoped org and project to null
      // if scope changed to GLOBAL set org and project to null

      if (newScope === HINT_SCOPE_LABELS.PROJECT) {
        setValue('organization_id', scopeOrganizationId, { shouldDirty: true })
        setValue('project_id', scopeProjectId, { shouldDirty: true })
      } else if (newScope === HINT_SCOPE_LABELS.ORGANIZATION) {
        setValue('organization_id', scopeOrganizationId, { shouldDirty: true })
        setValue('project_id', null, { shouldDirty: true })
      } else {
        setValue('organization_id', null, { shouldDirty: true })
        setValue('project_id', null, { shouldDirty: true })
      }
    },
    [setValue, scopeOrganizationId, scopeProjectId]
  )

  const handleRemoveStringFromList = useCallback(
    (valueToRemove, fieldName) => {
      const currentList = getValues(fieldName)
      setValue(
        fieldName,
        currentList.filter(item => item !== valueToRemove),
        { shouldDirty: true }
      )
    },
    [getValues, setValue]
  )

  const handleAddStringToList = useCallback(
    (valueToAdd, fieldName) => {
      const currentList = getValues(fieldName)
      setValue(fieldName, [...currentList, valueToAdd], { shouldDirty: true })
    },
    [setValue, getValues]
  )

  const handleOnClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  const languagesStillAvailable = useMemo(() => {
    return ALL_LANGUAGES.filter(lang => !currentLanguages.includes(lang))
  }, [currentLanguages])

  const roleTypesStillAvailable = useMemo(() => {
    return ALL_ROLE_TYPES.filter(roleType => !currentRoleTypes.includes(roleType))
  }, [currentRoleTypes])

  const handleUpdateHint = useCallback(
    async data => {
      setIsSaving(true)

      try {
        const result = await updateHintFirebaseFunction({ hintId: hint.id, hintData: data })
        onSaveFinished(result)
        toast({
          title: 'Hint updated successfully 🎉',
          description: 'No need to reload anything',
        })
        handleOnClose()
      } catch (error) {
        console.error('error updating hint', error)
        toast({
          variant: 'destructive',
          title: 'Updating hint failed 😢',
          description: 'Check console for details and try again.',
        })
      } finally {
        setIsSaving(false)
      }
    },
    [hint?.id, onSaveFinished, handleOnClose, toast]
  )
  const handleCreateHint = useCallback(
    async data => {
      setIsSaving(true)
      try {
        const result = await createHintFirebaseFunction({ hintData: data })
        console.log('Created hint result', result)
        onSaveFinished(result)
        toast({
          title: 'Hint created successfully 🎉',
          description: 'No need to reload anything',
        })
        handleOnClose()
      } catch (error) {
        console.error('error creating hint', error)
        toast({
          variant: 'destructive',
          title: `Couldn't create hint 😢`,
          description: 'Check console for details and try again.',
        })
      } finally {
        setIsSaving(false)
      }
    },
    [onSaveFinished, handleOnClose, toast]
  )

  const handleDeleteHint = useCallback(async () => {
    if (!hint?.id) {
      console.error('No hint id to delete')
      return
    }
    setIsDeleting(true)
    try {
      await deleteHintFirebaseFunction({ hintId: hint.id })
      toast({
        title: 'Hint deleted 💥',
        description: 'The hint has been deleted',
      })
      onDeleteFinished(hint?.id)
      handleOnClose()
    } catch (err) {
      console.error('Error deleting hint', err)
      toast({
        title: 'Error deleting hint',
        description: err.message,
        status: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }, [hint?.id, handleOnClose, toast, onDeleteFinished])

  const onSubmit = useCallback(
    data => {
      let cleanedData = { ...data }
      if (cleanedData?.context === '') {
        cleanedData.context = null
      }
      if (cleanedData.id) {
        handleUpdateHint(cleanedData)
      } else {
        handleCreateHint(cleanedData)
      }
    },
    [handleCreateHint, handleUpdateHint]
  )

  return (
    <Dialog open={isOpen} onClose={handleOnClose} size="5xl">
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventEnterKeySubmission}>
        {/* <DialogTitle>
          <span className="text-zinc-600">Editing hint</span>
          <div className="line-clamp-1 pt-2 text-sm capitalize text-zinc-800">{title}</div>
        </DialogTitle> */}
        {/* <DialogDescription className="max-w-prose rounded-md bg-zinc-50 p-4 text-zinc-700"></DialogDescription> */}
        <DialogBody className="text-zinc-900 dark:text-white">
          <Fieldset className="mb-4 rounded-md border border-zinc-100 bg-zinc-50 p-4">
            <FieldGroup>
              <Field>
                <Label>
                  <span className="text-base font-semibold">Title</span>
                </Label>
                <Input {...register('title')} />
              </Field>
              <Field>
                <Label>
                  <span className="text-base font-semibold">Context</span>
                </Label>
                <Description>
                  Human description of then to use this hint. This will be part of vector search.
                  <span className="block font-semibold text-zinc-700">
                    Leave blank and it will be autogenerated.
                  </span>
                </Description>
                <Textarea rows={3} {...register('context')} />
              </Field>
            </FieldGroup>
            <FieldGroup className="pt-8">
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                <MFormContentTypeEditor
                  formControl={control}
                  availableContentTypes={ALL_LANGUAGES}
                />
                <MFormLanguagesEditor
                  formControl={control}
                  availableLanguages={languagesStillAvailable}
                  onAddLanguage={langToAdd => handleAddStringToList(langToAdd, 'languages')}
                  onRemoveLanguage={langToRemove =>
                    handleRemoveStringFromList(langToRemove, 'languages')
                  }
                />
              </div>
            </FieldGroup>
            <FieldGroup className="pt-8">
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                <MFormServicesEditor
                  formControl={control}
                  onAddService={service => handleAddStringToList(service, 'services')}
                  onRemoveService={service => handleRemoveStringFromList(service, 'services')}
                />
                <MFormLibrariesEditor
                  formControl={control}
                  onAddLibrary={library => handleAddStringToList(library, 'libraries')}
                  onRemoveLibrary={library => handleRemoveStringFromList(library, 'libraries')}
                />
              </div>
            </FieldGroup>
            <FieldGroup className="pt-8">
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                <MFormRoleTypesEditor
                  formControl={control}
                  availableRoleTypes={roleTypesStillAvailable}
                  onRemoveRoleType={roleType => {
                    handleRemoveStringFromList(roleType, 'role_types')
                  }}
                  onAddRoleType={roleType => {
                    handleAddStringToList(roleType, 'role_types')
                  }}
                />
                <Field className="flex flex-col justify-between rounded-sm bg-zinc-100 p-4">
                  <div>
                    <Label>
                      <span className="text-base font-semibold">Hint Scope</span>
                    </Label>
                    <Description>How wide should the hint be used?</Description>
                  </div>

                  <Listbox
                    onChange={e => {
                      updateHintScope(e)
                    }}
                    value={hintScope}
                    className="max-w-48"
                  >
                    {getAllHintScopeNames()?.map(scopeName => (
                      <ListboxOption key={scopeName} value={scopeName}>
                        <ListboxLabel>{scopeName}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </Listbox>
                </Field>
              </div>
            </FieldGroup>
          </Fieldset>

          <Fieldset className="mb-4 rounded-md border border-zinc-100 bg-zinc-50 p-4 pt-8">
            <FieldGroup>
              <Field>
                <Controller
                  control={control}
                  name="value"
                  render={({ field: { onChange, value } }) => (
                    <OCodeEditor
                      language={currentContentType}
                      code={value}
                      editable={!isSaving}
                      onCodeChange={onChange}
                    />
                  )}
                />
              </Field>
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions className="flex flex-row items-start justify-start">
          {!!hint?.id && (
            <div className="flex-1">
              <Popover defaultOpen={true}>
                <PopoverTrigger asChild>
                  <Button disabled={isWorking} color="red">
                    {isDeleting ? 'Deleting...' : 'Delete'}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <div>
                    <div className="mb-4 flex flex-row justify-between">
                      <h5 className="text-md font-semibold text-zinc-700">Are you sure? 🧨</h5>
                      <PopoverClose aria-label="Cancel delete">
                        <XMarkIcon className="h-5 w-5" />
                      </PopoverClose>
                    </div>
                    <p className="text-sm text-zinc-500">
                      Do yo want to delte this hint?{' '}
                      <span className="block font-semibold">There&rsquo;s no undo.</span>
                    </p>
                    <div className="mt-6 flex justify-end">
                      <PopoverClose asChild aria-label="Cancel delete">
                        <Button disabled={isWorking} outline className="">
                          Cancel
                        </Button>
                      </PopoverClose>
                      <Button
                        disabled={isWorking}
                        color="red"
                        onClick={handleDeleteHint}
                        className="ml-2"
                      >
                        {isDeleting ? 'Deleting...' : 'Yes, delete hint'}
                      </Button>
                    </div>
                  </div>
                  <PopoverArrow />
                </PopoverContent>
              </Popover>
            </div>
          )}
          <Button disabled={isWorking} plain onClick={handleOnClose}>
            Cancel
          </Button>
          <Button disabled={!isDirty || isWorking} type="submit">
            {isSaving ? 'Saving...' : 'Save changes'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

OHintEditorDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  hint: PropTypes.shape({
    id: PropTypes.string,
    organization_id: PropTypes.string,
    project_id: PropTypes.string,
    iteration_id: PropTypes.string,
    title: PropTypes.string,
    content_type: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    services: PropTypes.arrayOf(PropTypes.string),
    libraries: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    context: PropTypes.string,
    role_types: PropTypes.arrayOf(PropTypes.string),
  }),
  scopeOrganizationId: PropTypes.string,
  scopeProjectId: PropTypes.string,
  onSaveFinished: PropTypes.func,
  onDeleteFinished: PropTypes.func,
}
