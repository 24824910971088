import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@/components/catalyst/avatar'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/catalyst/table'

export default function MTableSkeleton({
  howManyProjectsToFake = 5,
  showAvatar = true,
  labels = ['Loading', 'Loading', 'Loading', 'Loading'],
}) {
  return (
    <Table grid className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
      <TableHead>
        <TableRow>
          {labels.map((label, idx) => (
            <TableHeader id={idx} key={idx}>
              {label}
            </TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from(Array(howManyProjectsToFake))?.map((el, idx) => (
          <TableRow id={idx} key={idx}>
            {labels.map((label, index) => (
              <Fragment key={`${idx}-${index}`}>
                {index === 0 ? (
                  <TableCell id={`${idx}-${index}`}>
                    <div key={`${idx}-${index}`} className="flex items-center gap-4">
                      {showAvatar && <Avatar className="size-12 animate-pulse bg-zinc-200" />}
                      <div>
                        <div className="h-[1rem] w-64 animate-pulse rounded-full bg-zinc-300"></div>
                      </div>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell id={`${idx}-${index}`} className="w-42 text-zinc-500">
                    <div className="h-[1rem] animate-pulse rounded-full bg-zinc-300"></div>
                  </TableCell>
                )}
              </Fragment>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

MTableSkeleton.propTypes = {
  howManyProjectsToFake: PropTypes.number,
  showAvatar: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
}
