import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function DocumentAction({ action, isLast = false }) {
  return (
    <GenericAction
      action={action}
      detailsContent={action.args?.documentation || 'No documentation provided'}
      actionIcon={ClipboardDocumentListIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Create Stage documentation" />
      <AActionContentGenericLayout>
        <AActionContentDescription content="Stage documentation created" />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DocumentAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
