import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { getIteration } from '@/services/Firebase'

import { Button } from '@/components/catalyst/button'

import MElementsList from '@/components/molecules/iteration-details/MElementsList'
import { useToast } from '@/components/ui/use-toast'

import OiterationDetailsSkeleton from '@/components/organisms/project-details/OiterationDetailsSkeleton'
// import MNewIterationDialog from '@/components/molecules/iteration-details/MNewIterationDialog'
import MStartStopIterationCard from '@/components/molecules/iteration-details/MStartStopIterationCard'
import MIterationStatusChangeAwaiting from '@/components/molecules/iteration-details/MIterationStatusChangeAwaiting'
import { Textarea } from '@/components/catalyst/textarea'

import { createIterationCommandFirebaseFunction } from '@/services/Firebase'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { ITERATION_COMMANDS } from '@/const/const'

const SHOW_ALL_DETAILS_BY_DEFAULT = false
const SHOULD_START_GUNSLINGER = window?.location?.host?.startsWith('old.') ? false : true

function MContinuationPrompt({ iterationId }) {
  const [prompt, setPrompt] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [shouldHide, setShouldHide] = useState(false)

  const { toast } = useToast()

  const sendIterationCommand = useCallback(async () => {
    if (!prompt || prompt?.length === 0 || !iterationId) {
      toast({
        variant: 'destructive',
        title: 'We need instructions!',
        description: 'Please provide instructions on how to continue',
      })
      return
    }
    setIsWorking(true)
    try {
      const payload = {
        iterationId: iterationId,
        command: ITERATION_COMMANDS.EXTEND,
        commandArgs: { prompt },
        dontStartGunslinger: !SHOULD_START_GUNSLINGER,
      }

      await createIterationCommandFirebaseFunction(payload)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
        iterationId: iterationId,
        prompt: prompt,
      })
      setPrompt(false)
      setShouldHide(true)
    } catch (error) {
      console.error('Error creating continuation prompt', error)
      toast({
        variant: 'destructive',
        title: 'Error extending iteration 😔',
        description: 'Try refreshing the page or contact Proofs team.',
      })
    } finally {
      setIsWorking(false)
    }
  }, [prompt, iterationId, toast])

  const shouldAllowSubmitting = useMemo(() => {
    return !isWorking && prompt && prompt?.length > 0
  }, [prompt, isWorking])

  if (shouldHide) {
    return null
  }

  return (
    <>
      <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" />
      <div className="mx-auto w-3/5">
        <div className="mx-12 flex flex-col items-center justify-center rounded-md border border-zinc-200 bg-zinc-50 p-4 shadow-inner">
          <Textarea
            className="w-full"
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            placeholder="Your instructions on what to do next..."
          />
          <div className="mt-4 flex w-full justify-end">
            <Button
              disabled={!shouldAllowSubmitting}
              className="ml-4 cursor-pointer"
              color="zinc"
              onClick={sendIterationCommand}
            >
              {isWorking ? 'Working...' : 'Continue iteration'}
            </Button>
          </div>
          <div className="mt-4 flex w-full items-center justify-end">
            <p className="text-xs text-zinc-500">
              Proofs will continue iteration with provided instructions
            </p>
          </div>
        </div>
      </div>
      {/* <div className="mb-4 mt-4 w-full border border-dashed border-zinc-200" /> */}
    </>
  )
}

MContinuationPrompt.propTypes = {
  iterationId: PropTypes.string,
}

/**
 * A component for displaying details of an iteration.
 * @param {Object} props - The props for the component.
 * @param {Object} props.iterationMeta - The iteration to display.
 * @param {Boolean} props.isNewestIteration - Whether the iteration is the newest one.
 * @param {Number} props.lastTick - The last tick time.
 * @param {Function} props.onIterationCreated - The function to call when a new iteration is created.
 * @returns {JSX.Element} The JSX element for the component.
 */
export default function OIterationDetails({
  iterationMeta,
  isNewestIteration = false,
  lastTick = Date.now(),
  onIterationCreated,
}) {
  const [isNewIterationDialogOpen, setIsNewIterationDialogOpen] = useState(false)
  const [lastIterationMeta, setLastIterationMeta] = useState(iterationMeta)
  const [iteration, setIteration] = useState({})
  const [isIterationLoading, setIsIterationLoading] = useState(true)
  const [iterationLoadingError, setIterationLoadingError] = useState(null)
  const [filterOutLLMActions, setFilterOutLLMActions] = useState(true)

  const { toast } = useToast()

  const [isAwaingCommand, isWorkingOnStatusChange] = useMemo(() => {
    const isAwaiting = iterationMeta?.awaitingCommand ? true : false
    const isDuringTransition = iterationMeta?.awaitingCommand?.active ? true : false
    return [isAwaiting, isDuringTransition]
  }, [iterationMeta])

  const allowContinuationPrompt = useMemo(() => {
    return !isAwaingCommand && !isWorkingOnStatusChange && iteration.status === 'DONE'
  }, [isAwaingCommand, isWorkingOnStatusChange, iteration.status])

  useEffect(() => {
    if (!_.isEqual(iterationMeta, lastIterationMeta)) {
      setLastIterationMeta(iterationMeta)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (iterationMeta?.id !== lastIterationMeta?.id) {
      // only set loading if the iteration id has changed to limit flickering on loading
      setIsIterationLoading(true)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (lastIterationMeta) {
      getIteration({ iterationId: lastIterationMeta.id })
        .then(iterationData => {
          setIteration(iterationData.data)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
          })
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIterationLoadingError(err)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN_ERROR, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
            error: err.message,
          })
          toast({
            variant: 'destructive',
            title: 'Error loading iteration details 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [lastIterationMeta, toast])

  const elements = useMemo(() => {
    const elements = Object.entries(iteration?.elements || {}).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  const elementsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && elements) {
      return elements?.map(element => {
        const dateCreated = new Timestamp(element?.createdAt)?.toDate() || Date.now()
        const dateUpdated = new Timestamp(element?.updatedAt)?.toDate() || Date.now()
        return {
          ...element,
          updatedAt: dateUpdated,
          createdAt: dateCreated,
          updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
          repoURI: iteration?.repoURI,
        }
      })
    }
  }, [elements, iteration?.repoURI, lastTick])

  const isDone = useMemo(() => {
    return iteration.status === 'DONE'
  }, [iteration])

  const canCreateNewIteration = useMemo(() => {
    return isDone
  }, [isDone])

  const groupedElements = useMemo(() => {
    const result = []
    if (iteration && elementsWithRelativeTime) {
      if (iteration?.continuationPrompts?.length > 0) {
        let lastContinuationPromptIndex = 0
        for (const continuationPrompt of iteration.continuationPrompts) {
          const afterElementIndex = continuationPrompt?.afterElementIndex
          // all elements with index less than the continuation prompt afterElementIndex should be grouped
          const elementsToGroup = elementsWithRelativeTime.filter(
            element =>
              element.index > lastContinuationPromptIndex && element.index <= afterElementIndex
          )
          result.push({
            elements: elementsToGroup,
            prompt: continuationPrompt?.prompt,
            createdBy: continuationPrompt?.createdBy,
          })
          lastContinuationPromptIndex = afterElementIndex
        }
      } else {
        // push all elements to first group
        result.push({ elements: elementsWithRelativeTime })
      }
    }

    return result
  }, [iteration, elementsWithRelativeTime])

  // if iteration status is not done, scroll the page to the bottom on iteration updates
  // also do this only if the user is scrolled to the bottom
  // Adding scrollContainer.current as a dependency

  if (isIterationLoading) {
    return <OiterationDetailsSkeleton />
  }

  return (
    <>
      <div className="mt-8">
        {groupedElements.map((group, index) => {
          return (
            <div key={index} className="mb-12">
              <MElementsList
                elements={group.elements}
                expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
                filterOutLLMActions={filterOutLLMActions}
              />
              {group?.prompt && (
                <div className="flex flex-1 justify-end p-8 pr-0">
                  <div className="flex max-w-prose flex-col items-start rounded-2xl rounded-tr-none bg-zinc-800 px-4 py-4 text-zinc-50">
                    <div className="whitespace-pre-line text-base">{group.prompt}</div>
                    <div className="mt-4 text-sm font-semibold">{group.createdBy}</div>
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {/* {isNewestIteration && (
        <>
          <div className="mt-12 flex items-center justify-center">
            <Button
              disabled={!canCreateNewIteration}
              onClick={() => {
                setIsNewIterationDialogOpen(true)
              }}
              className={!canCreateNewIteration ? 'bg-zinc-600 hover:cursor-wait' : ''}
            >
              {!canCreateNewIteration ? 'Working....' : 'Create new iteration from this point'}
            </Button>
          </div>
          <MNewIterationDialog
            projectId={iteration?.projectId}
            isOpen={isNewIterationDialogOpen}
            onClose={() => {
              setIsNewIterationDialogOpen(false)
            }}
            environment={iteration?.environment}
            environmentId={iteration?.environmentId}
            sourceIterationData={iteration}
            isFirstIterationForProject={false}
            onIterationCreated={onIterationCreated}
          />
        </>
      )} */}
      {allowContinuationPrompt && <MContinuationPrompt iterationId={iterationMeta?.id} />}
      <div className="mb-24"></div>

      {!isAwaingCommand && <MStartStopIterationCard iterationMeta={iterationMeta} />}
      {isAwaingCommand && (
        <MIterationStatusChangeAwaiting
          iterationId={iterationMeta?.id}
          isOpen={isAwaingCommand}
          awaitingCommandMeta={iterationMeta?.awaitingCommand || {}}
        />
      )}
    </>
  )
}

OIterationDetails.propTypes = {
  iterationMeta: PropTypes.shape({
    id: PropTypes.string,
    iterationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    inputTokens: PropTypes.number,
    outputTokens: PropTypes.number,
    elapsedTime: PropTypes.number,
    prompt: PropTypes.string,
    createdAt: PropTypes.instanceOf(Timestamp),
    updatedAt: PropTypes.instanceOf(Timestamp),
    elements: PropTypes.object,
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    environment: PropTypes.object,
    repoURI: PropTypes.string,
    awaitingCommand: PropTypes.object,
    teamId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  onIterationCreate: PropTypes.func,
  isIterationCreating: PropTypes.bool,
  isNewestIteration: PropTypes.bool,
  onIterationCreated: PropTypes.func,
}
