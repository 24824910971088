import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut as signoutFunction,
} from 'firebase/auth'
import { auth } from '@/services/Firebase'

export const signIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    if (err.code === 'auth/invalid-credential') {
      throw new Error('Invalid credentials')
    }
    throw new Error('Unknown error')
  }
}

export const signOut = async () => {
  await signoutFunction(auth)
}

export const resetPassword = async email => {
  await sendPasswordResetEmail(auth, email)
}
