import classNames from 'classnames'
import { ACTION_STATUSES } from '@/const/const.js'
import { useMemo } from 'react'
import _ from 'lodash'
import { actionShape } from '@/components/propTypes.js'
import PropTypes from 'prop-types'

export default function AContentTitle({ action, title }) {
  const elapsedTimeLabel = useMemo(() => {
    // round elasped time to 2 decimal places
    if (!action?.elapsedTime) {
      return ''
    }
    const elapsedTime = _.round(action.elapsedTime, 2)
    return `${elapsedTime} sec`
  }, [action])
  return (
    <div className="text-xs font-bold text-zinc-800 ">
      {title}
      <span
        className={classNames(
          !(action.status === ACTION_STATUSES.DONE) && 'animate-pulse',
          'ml-1 pb-1 text-xs font-normal text-gray-500'
        )}
      >
        {action.status === ACTION_STATUSES.DONE ? `~ ${elapsedTimeLabel}` : '(running...)'}
      </span>{' '}
    </div>
  )
}

AContentTitle.propTypes = {
  action: actionShape,
  title: PropTypes.string,
}
