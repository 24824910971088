import PropTypes from 'prop-types'
import MTableSkeleton from '@/components/molecules/MTableSkeleton'

export default function MCardTableSkeleton({
  title = 'Loading...',
  howManyProjectsToFake = 5,
  labels = ['Loading', 'Loading', 'Loading', 'Loading'],
}) {
  return (
    <div
      role="status"
      className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6"
    >
      <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between pb-6 pt-2 sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="animate-pulse text-xl font-semibold leading-6 text-gray-900">{title}</h3>
        </div>
        <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
          <button
            type="button"
            disabled
            className="bg-mimirBlue-500 hover:bg-mimirBlue-700 focus-visible:outline-mimirBlue-500 relative inline-flex animate-pulse items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            <p className="h-[1rem] w-36"></p>
          </button>
        </div>
      </div>
      <MTableSkeleton howManyProjectsToFake={howManyProjectsToFake} labels={labels} />
    </div>
  )
}

MCardTableSkeleton.propTypes = {
  title: PropTypes.string,
  howManyProjectsToFake: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string),
}
