import { useCallback, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { query, where } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { useCollection } from 'react-firebase-hooks/firestore'
import { DateTime } from 'luxon'
import OPageWrapper from '@/components/organisms/OPageWrapper'
import useStore from '@/stores/useStore'

import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'

import { ROUTES } from '@/const/routes'

import { iterationFeedbackCollectionRef } from '@/services/Firebase'

import { Badge } from '@/components/catalyst/badge'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function OOrgProjectIterationFeedbackTable({ organization }) {
  return (
    <div className="mt-12 rounded-md bg-white px-4 py-8 ring-1 ring-zinc-200 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{organization.name}</h1>
          <p className="mt-2 text-sm text-gray-700">
            All projects with feedback captured for iterations
          </p>
        </div>
      </div>
      {organization?.projects?.map(project => (
        <div
          key={project.id}
          className="-mx-4 mt-10 bg-white ring-1 ring-zinc-100 sm:mx-0 sm:rounded-lg"
        >
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="w-60 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {project.name} (Iteration Id)
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-zinc-900 lg:table-cell"
                >
                  Sentiment
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-zinc-900 lg:table-cell"
                >
                  Reasons
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm  font-normal text-zinc-900"
                >
                  Comment
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm  font-normal text-zinc-900"
                >
                  Submitted by
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm  font-normal text-zinc-900"
                >
                  Submitted on
                </th>
              </tr>
            </thead>
            <tbody>
              {project?.feedback?.map((feedback, index) => (
                <>
                  <tr key={feedback.id}>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-transparent',
                        'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                      )}
                    >
                      <div className="font-medium text-gray-900 hover:underline">
                        <Link
                          target="_blank"
                          to={`/projects/${project.id}?iteration=${feedback.iterationId}`}
                        >
                          {feedback.iterationId}
                        </Link>
                      </div>

                      {index !== 0 ? (
                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                      ) : null}
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'px-3 py-3.5 text-sm text-gray-500'
                      )}
                    >
                      <div className="flex items-center justify-start ">
                        <Badge
                          color={feedback.sentiment === 'negative' ? 'red' : 'green'}
                          className="font-mono"
                        >
                          {feedback.sentiment}
                        </Badge>
                      </div>
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      <div className="flex w-24 flex-col items-start justify-center space-y-2">
                        {feedback?.reasons?.map(reason => (
                          <Badge key={reason} color="zinc" className="font-mono">
                            {reason}
                          </Badge>
                        ))}
                      </div>
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden w-96 max-w-96 whitespace-pre-line px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {feedback.comment}
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {feedback.createdBy}
                    </td>
                    <td
                      className={classNames(
                        index === 0 ? '' : 'border-t border-gray-200',
                        'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                      )}
                    >
                      {feedback.createdAtRelative}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}

const BREADCRUMBS = [
  {
    name: 'Iteration feedback',
    href: ROUTES.MANAGE_VMS,
    current: true,
  },
]

export default function PIterationFeedback() {
  const projects = useStore(state => state.projects)
  const isAfterInitialLoad = useStore(state => state.isAfterInitialLoad)
  const organizations = useStore(state => state.organizations)
  const isSuperAdminFn = useStore(state => state.isSuperAdmin)
  const userRoles = useStore(state => state.userRoles)
  const getProjectBrokenDownByOrganization = useStore(
    state => state.getProjectBrokenDownByOrganization
  )

  const projectsByOrganization = useMemo(() => {
    if (projects && projects.length > 0 && organizations && organizations.length > 0) {
      return getProjectBrokenDownByOrganization()
    }
    return null
  }, [projects, getProjectBrokenDownByOrganization, organizations])

  const isSuperAdmin = useMemo(() => {
    if (userRoles) {
      return isSuperAdminFn()
    } else {
      return false
    }
  }, [isSuperAdminFn, userRoles])

  const iterationFeedbackQuery = useMemo(() => {
    return query(iterationFeedbackCollectionRef)
  }, [])

  // Actual iterations retrieval
  const [iterationFeedbackSnapshot, iterationFeedbackSnapshotLoading] =
    useCollection(iterationFeedbackQuery)

  const iterationFeedback = useMemo(() => {
    if (!iterationFeedbackSnapshot) {
      return []
    }
    return iterationFeedbackSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }, [iterationFeedbackSnapshot])

  const iterationFeedbackWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (iterationFeedback) {
      return iterationFeedback?.map(feedback => ({
        ...feedback,
        updatedAtRelative: DateTime.fromJSDate(
          feedback?.updatedAt?.toDate() || Date.now()
        ).toFormat('LLL dd, hh:mm a'),
        createdAtRelative: DateTime.fromJSDate(
          feedback?.createdAt?.toDate() || Date.now()
        ).toFormat('LLL dd, hh:mm a'),
      }))
    }
  }, [iterationFeedback])

  const organizationWithProjectsAndFeedback = useMemo(() => {
    if (!projectsByOrganization || !iterationFeedbackWithRelativeTime) {
      return null
    }

    const organizations = []

    projectsByOrganization.forEach(organization => {
      const projects = []
      organization.projects.forEach(project => {
        const projectFeedback = iterationFeedbackWithRelativeTime
          .filter(feedback => feedback.projectId === project.id)
          .sort((a, b) => (a.updatedAt?.seconds < b.updatedAt?.seconds ? -1 : 1))
        if (projectFeedback.length > 0) {
          projects.push({ ...project, feedback: projectFeedback })
        }
      })
      if (projects.length > 0) {
        organizations.push({ ...organization, projects })
      }
    })
    return organizations
  }, [projectsByOrganization, iterationFeedbackWithRelativeTime])

  if (!isSuperAdmin) {
    return null
  }

  if (!isAfterInitialLoad || iterationFeedbackSnapshotLoading) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div role="status" className="px-2 py-6 lg:px-24 lg:py-12 ">
          <MCardTableSkeleton
            title="Loading feeback..."
            howManyProjectsToFake={20}
            labels={['Project', 'Sentiment', 'Reasons', 'Comment', 'Submitted by', 'Submitted on']}
          />
        </div>
      </OPageWrapper>
    )
  }

  if (!projectsByOrganization || projectsByOrganization.length === 0) {
    return (
      <OPageWrapper breadcrumbs={BREADCRUMBS}>
        <div className="mt-12 text-4xl font-bold tracking-tight text-gray-400 sm:text-6xl">
          No Feedback on iterations found
        </div>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={BREADCRUMBS}>
      <div className="pb-48">
        {organizationWithProjectsAndFeedback.map(organization => (
          <OOrgProjectIterationFeedbackTable key={organization.id} organization={organization} />
        ))}
      </div>
    </OPageWrapper>
  )
}
