import { useCallback, useState } from 'react'
import * as PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogTitle } from '@/components/catalyst/dialog.jsx'
import { Button } from '@/components/catalyst/button.jsx'

export default function MConfirmationDialog({ open, onCancel, onConfirm, title }) {
  const [confirmationInProgress, setConfirmationInProgress] = useState(false)

  const handleConfirmation = useCallback(async () => {
    setConfirmationInProgress(true)
    await onConfirm()
    setConfirmationInProgress(false)
  }, [onConfirm])

  return (
    <Dialog
      size="sm"
      open={open}
      onClose={onCancel}
      className="flex flex-col items-center justify-center rounded-lg bg-white p-4 shadow-lg"
    >
      <DialogTitle className="mb-4 flex justify-center text-xl font-semibold">
        {title ?? 'Confirm action'}
      </DialogTitle>
      <DialogActions className="flex w-full justify-between px-6">
        <Button
          disabled={confirmationInProgress}
          plain
          onClick={onCancel}
          className="rounded-md border border-gray-300 px-4 py-2 hover:bg-gray-100"
        >
          Cancel
        </Button>
        <Button
          disabled={confirmationInProgress}
          className={`ml-4 rounded-md px-4 py-2 text-white ${confirmationInProgress ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700'}`}
          onClick={handleConfirmation}
        >
          {confirmationInProgress ? 'In progress...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
}
