import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { PaperClipIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function ResearchAction({ action, isLast = false }) {
  let detailsContent = ''

  const messages = action?.args?.messages
  if (messages) {
    detailsContent += `## MESSAGES:\n\n`
    messages.forEach((message, index) => {
      detailsContent += `------------ ${index + 1} ${message?.role?.toUpperCase()}: ${message?.label}\n${message?.content}\n\n\n\n`
    })
  }

  const hints = action?.outputs?.[0]?.hints
  if (hints) {
    detailsContent += `## HINTS:\n`
    hints.forEach((hint, index) => {
      detailsContent += `### HINT ${index + 1}:\n` + '```python\n' + hint + '```\n\n'
    })
  }

  const solution = action?.outputs?.[0]?.solution
  if (solution) {
    detailsContent += `## SOLUTION:\n` + solution + '\n'
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={PaperClipIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Research" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.question || action.type || action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ResearchAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
