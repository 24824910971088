function getObjectValue(obj) {
  if (obj == null) {
    return { value: null, type: 'json' }
  }
  if (obj._type === 'pydantic') {
    try {
      return { value: JSON.parse(obj.value), type: 'json' }
    } catch (e) {
      console.error('Could not parse json content for ', obj)
      return { value: obj, type: 'object' }
    }
  } else {
    return { value: obj, type: 'object' }
  }
}

export { getObjectValue }
