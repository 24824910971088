import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Label,
} from '@/components/catalyst/fieldset.jsx'
import { Input } from '@/components/catalyst/input.jsx'
import { Textarea } from '@/components/catalyst/textarea.jsx'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox.jsx'
import { TEMPLATE_WIDGET_TYPE } from '@/const/const.js'
import { Checkbox, CheckboxField } from '@/components/catalyst/checkbox.jsx'
import { Button } from '@/components/catalyst/button.jsx'
import { PlusCircleIcon, TrashIcon } from 'lucide-react'

const TemplateForm = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext() // Retrieve the context of the parent form

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'template.environmentVariables',
  })

  return (
    <>
      <FieldGroup className="space-y-8 pt-8">
        <div>
          <Field>
            <Label>Template name</Label>
            <Input
              {...register('template.name', {
                required: 'Template name is required',
              })}
              invalid={!!errors.template?.name}
            />
          </Field>
          {errors.template?.name && (
            <ErrorMessage className="">{errors.template.name.message}</ErrorMessage>
          )}
        </div>

        <div>
          <Field>
            <Label>Template description</Label>
            <Textarea
              {...register('template.description', {})}
              invalid={!!errors.template?.description}
              rows={10}
            />
          </Field>
          {errors.template?.description && (
            <ErrorMessage className="pt-0">{errors.template.description.message}</ErrorMessage>
          )}
        </div>
      </FieldGroup>

      <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
        Usecase & toolset
      </Description>
      <FieldGroup className="space-y-8 pt-8">
        <div>
          <Field>
            <Label>Usecase Id</Label>
            <Input
              {...register('template.usecase.usecaseId', {
                required: 'Please fill in the id of the usecase',
              })}
              invalid={!!errors.template?.usecase?.usecaseId}
            />
          </Field>
          {errors.template?.usecase?.usecaseId && (
            <ErrorMessage className="">{errors.template?.usecase?.usecaseId.message}</ErrorMessage>
          )}
        </div>
        <div>
          <Field>
            <Label>Toolsets</Label>
            <Input
              {...register('template.usecase.toolsets', {
                required: false,
              })}
              invalid={!!errors.template?.usecase?.toolsets}
            />
          </Field>
          {errors.template?.usecase?.toolsets && (
            <ErrorMessage className="">{errors.template?.usecase?.toolsets.message}</ErrorMessage>
          )}
        </div>
      </FieldGroup>

      <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
        Add environment variables for default template
      </Description>
      <FieldGroup className="mt-8 space-y-8   pb-12 sm:space-y-0 sm:pb-0">
        {fields.map((env, index) => (
          <div className="sm:grid sm:grid-cols-12 sm:items-start sm:gap-4 sm:py-4" key={index}>
            <div className="border border-zinc-200 p-4 sm:col-span-11 sm:mt-0">
              <Description className="mb-4   pb-2 font-mono font-bold text-zinc-700">
                New environment variable
              </Description>
              <Field className="">
                <Label>Name</Label>
                <Input
                  {...register(`template.environmentVariables.${index}.name`, {
                    required: 'Please fill in a name',
                  })}
                  placeholder="Name of the variable"
                  invalid={!!errors?.template?.environmentVariables?.[index]?.name}
                />
                {errors?.template?.environmentVariables?.[index]?.name && (
                  <ErrorMessage className="pt-0">
                    {errors?.template?.environmentVariables?.[index]?.name.message}
                  </ErrorMessage>
                )}
              </Field>
              <Field className="pt-2">
                <Label>Group</Label>
                <Input
                  {...register(`template.environmentVariables.${index}.group`, {
                    required: 'Please fill in a group',
                  })}
                  placeholder="Group of the variable"
                  invalid={!!errors?.template?.environmentVariables?.[index]?.group}
                />
                {errors?.template?.environmentVariables?.[index]?.name && (
                  <ErrorMessage className="pt-0">
                    {errors?.template?.environmentVariables?.[index]?.group.message}
                  </ErrorMessage>
                )}
              </Field>
              <Field className="pt-2">
                <Label>Key</Label>
                <Input
                  {...register(`template.environmentVariables.${index}.key`, {
                    required: 'Please fill in a key',
                  })}
                  placeholder="Key name"
                  invalid={!!errors?.template?.environmentVariables?.[index]?.key}
                />
                {errors?.template?.environmentVariables?.[index]?.key && (
                  <ErrorMessage className="pt-0">
                    {errors?.template?.environmentVariables?.[index]?.key.message}
                  </ErrorMessage>
                )}
              </Field>
              <Field className="pt-2">
                <Label>Description</Label>
                <Textarea
                  {...register(`template.environmentVariables.${index}.description`, {})}
                  placeholder="Description"
                  inputClassName="redacted-input focus:display-redacted-input"
                  invalid={!!errors?.template?.environmentVariables?.[index]?.description}
                />
                {errors?.template?.environmentVariables?.[index]?.description && (
                  <ErrorMessage className="pt-0">
                    {errors?.template?.environmentVariables?.[index]?.description.message}
                  </ErrorMessage>
                )}
              </Field>
              <Field className="pt-2 ">
                <Label>Placeholder</Label>
                <Input
                  {...register(`template.environmentVariables.${index}.placeholder`, {})}
                  placeholder="Placeholder"
                  invalid={!!errors?.template?.environmentVariables?.[index]?.placeholder}
                />
                {errors?.template?.environmentVariables?.[index]?.placeholder && (
                  <ErrorMessage className="pt-0">
                    {errors?.template?.environmentVariables?.[index]?.placeholder.message}
                  </ErrorMessage>
                )}
              </Field>
              <Field className="mt-4 flex items-center justify-between">
                <Label>Widget type</Label>
                <Controller
                  control={control}
                  name={`template.environmentVariables.${index}.type`}
                  render={({ field: { onChange, value } }) => (
                    <Listbox onChange={onChange} value={value} className="max-w-48">
                      <ListboxOption
                        key={TEMPLATE_WIDGET_TYPE.TEXT_SHORT}
                        value={TEMPLATE_WIDGET_TYPE.TEXT_SHORT}
                      >
                        <ListboxLabel className="capitalize">Short text</ListboxLabel>
                      </ListboxOption>
                      <ListboxOption
                        key={TEMPLATE_WIDGET_TYPE.TEXT_LONG}
                        value={TEMPLATE_WIDGET_TYPE.TEXT_LONG}
                      >
                        <ListboxLabel className="capitalize">Long text</ListboxLabel>
                      </ListboxOption>
                    </Listbox>
                  )}
                />
              </Field>
              <div className="mt-4 flex items-center gap-5">
                <Field className=" flex items-center ">
                  <Label>Is required</Label>
                  <Controller
                    control={control}
                    name={`template.environmentVariables.${index}.required`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxField className="ml-4">
                        <Checkbox name="required" checked={value} onChange={onChange} />
                      </CheckboxField>
                    )}
                  />
                </Field>
                <Field className="flex items-center ">
                  <Label>{"Don't encrypt"}</Label>
                  <Controller
                    control={control}
                    name={`template.environmentVariables.${index}.dontEncrypt`}
                    render={({ field: { onChange, value } }) => (
                      <CheckboxField className="ml-4">
                        <Checkbox name="dontEncrypt" checked={value} onChange={onChange} />
                      </CheckboxField>
                    )}
                  />
                </Field>
              </div>
            </div>

            <div className="just flex h-full w-full items-center justify-start sm:col-span-1 sm:mt-0">
              <Button color="red" onClick={() => remove(index)}>
                <TrashIcon />
              </Button>
            </div>
          </div>
        ))}
        <Button
          type="button"
          onClick={() =>
            append({
              name: '',
              key: '',
              description: '',
              required: false,
              placeholder: '',
              type: TEMPLATE_WIDGET_TYPE.TEXT_SHORT,
            })
          }
        >
          <PlusCircleIcon />
          Add Env
        </Button>
      </FieldGroup>
    </>
  )
}

TemplateForm.propTypes = {}

export default TemplateForm
