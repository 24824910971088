import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { Field, FieldGroup, Fieldset } from '@/components/catalyst/fieldset'
import { Label } from '@/components/catalyst/fieldset'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox'
import { UserIcon, CpuChipIcon, UserPlusIcon } from '@heroicons/react/16/solid'

import { Textarea } from '@/components/catalyst/textarea'
import OCodeEditor from '@/components/organisms/OCodeEditor'

/**
 * Editor for tools (function) JSON data
 * @param {object} props
 * @param {object} props.value
 * @param {funtion} props.onUpdate
 */
export function MToolsEditor({ value = {}, onUpdate = () => {} }) {
  const stringifiedValue = JSON.stringify(value, null, 2)
  const [changedValue] = useState(stringifiedValue)

  return <OCodeEditor code={changedValue} language="json" editable={false} />
}

/**
 * Displays editable prompt message
 * @param {object} props
 * @param {string} props.role
 * @param {string} props.name
 * @param {string} props.content
 * @param {string} props.label
 * @param {number} props.index
 * @param {function} props.updatePrompt
 * @returns {JSX.Element}
 */
function MPromptMessageRow({
  role = 'user',
  name = '',
  content = 'Prompt text',
  label = 'Label',
  index = 0,
  updatePrompt = () => {},
}) {
  const [changedContent, setChangedContent] = useState(content)
  const [changedRole, setChangedRole] = useState(role)
  const howManyRowsInContent = useMemo(() => {
    return changedContent.split('\n').length + 2
  }, [changedContent])

  const labelToDisplay = useMemo(() => {
    if (name && label) {
      return `${label} (name: ${name})`
    }
    return label ? label : `Message ${index + 1}`
  }, [label, index, name])

  return (
    <div>
      <div className="mb-2 mt-8 font-mono text-lg font-semibold first-letter:capitalize">
        {labelToDisplay}
      </div>
      <Fieldset className="mb-4 ml-4 rounded-md border border-l-8 border-zinc-100 bg-zinc-50 p-2">
        <FieldGroup>
          <Field>
            <Label>Role</Label>
            <Listbox name="role" defaultValue={changedRole} className="max-w-48">
              <ListboxOption value="user">
                <UserIcon />
                <ListboxLabel>User</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="system">
                <CpuChipIcon />
                <ListboxLabel>System</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="assistant">
                <UserPlusIcon />
                <ListboxLabel>Assistant</ListboxLabel>
              </ListboxOption>
            </Listbox>
          </Field>
          <Field>
            <Textarea rows={howManyRowsInContent} name="notes" value={content} />
          </Field>
        </FieldGroup>
      </Fieldset>
    </div>
  )
}

/**
 * LLM Playground Dialog
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {boolean} props.isOpen
 * @param {function} props.setIsOpen
 * @param {array} props.messages
 * @param {array} props.tools
 * @returns {JSX.Element}
 */
export default function OLLMPlaygroundDialog({
  title = 'Title',
  description = 'Description',
  isOpen = true,
  setIsOpen = () => {
    console.log('Called default OLLMPlaygroundDialog setIsOpen')
  },
  messages = [],
  tools = [],
}) {
  const [updatedMessages, setUpdatedMessages] = useState(messages)
  const [updatedTools, setUpdatedTools] = useState(tools)
  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} size="4xl">
        <DialogTitle>
          <span className="text-zinc-500">Editing:</span> {title}
        </DialogTitle>
        <DialogDescription className="max-w-prose rounded-md bg-zinc-50 p-4 text-zinc-700">
          {description}
        </DialogDescription>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {updatedMessages.map((msg, index) => (
            <MPromptMessageRow
              index={index}
              key={index}
              role={msg.role}
              content={msg.content}
              label={msg.label}
              name={msg.name}
            />
          ))}
          <div>
            <div className="mb-2 mt-8 font-mono text-lg font-semibold">Tools </div>

            <MToolsEditor value={updatedTools} />
          </div>
        </DialogBody>
        <DialogActions className="flex-row items-start justify-start">
          <Button className="place-self-end" onClick={() => alert('Not implemented yet')}>
            Simulate response
          </Button>
          <Button plain onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

OLLMPlaygroundDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  messages: PropTypes.arrayOf(PropTypes.object),
  tools: PropTypes.arrayOf(PropTypes.object),
}
