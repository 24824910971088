import { create } from 'zustand'
import _ from 'lodash'
import { doc, query, where, documentId, onSnapshot } from 'firebase/firestore'
import {
  firestoreDb,
  COLLECTIONS,
  organizationsCollectionRef,
  teamsCollectionRef,
  projectsCollectionRef,
} from '@/services/Firebase'

import { ACL_ROLES } from '@/const/const'

const createBatches = (array, size) => {
  const batches = []
  for (let i = 0; i < array.length; i += size) {
    batches.push(array.slice(i, i + size))
  }
  return batches
}

const useStore = create((set, get) => ({
  organizations: [],
  teams: [],
  projects: [],
  user: {},
  userRoles: {},
  isAfterInitialLoad: false,
  whatsLoaded: {
    organizations: false,
    teams: false,
    projects: false,
  },
  // store all Firestore unsubscribe functions to be called on unmount
  unsubscribeFunctions: [],
  activeIterationStatus: null,
  setActiveIterationStatus: status => {
    set({ activeIterationStatus: status })
  },
  // those unsubscribe functions are stored separately as they are called in different times
  userListenerUnsubscribeFn: () => {},
  userRolesListenerUnsubscribeFn: () => {},

  isEverythingLoaded: () => {
    return Object.values(get().whatsLoaded).every(value => value === true)
  },

  setLoaded: collection => {
    if (get().whatsLoaded[collection]) return

    set(state => {
      return {
        whatsLoaded: {
          ...state.whatsLoaded,
          [collection]: true,
        },
      }
    })
  },

  /**
   * Adds an unsubscribe function to the store on Firestore snapshot listeners
   * @param {*} unsubscribeFn
   */
  addUnsubscribeFunction: unsubscribeFn => {
    set(state => ({ unsubscribeFunctions: [...state.unsubscribeFunctions, unsubscribeFn] }))
  },

  unsubscribeFromOrgsTeamsProjectsListeners: () => {
    const { unsubscribeFunctions } = get()
    unsubscribeFunctions.forEach(unsub => unsub())
    set({
      unsubscribeFunctions: [],
    })
  },

  /**
   * Unsubscribes from all Firestore listeners on unmount
   */
  unsubscribeFromAllListeners: () => {
    get().unsubscribeFromOrgsTeamsProjectsListeners()

    if (get().userListenerUnsubscribeFn) {
      get().userListenerUnsubscribeFn()
    }

    if (get().userRolesListenerUnsubscribeFn) {
      get().userRolesListenerUnsubscribeFn()
    }

    set({
      userListenerUnsubscribeFn: () => {},
      userRolesListenerUnsubscribeFn: () => {},
    })
  },

  loadInitialData: async userId => {
    if (!userId) return

    //:TODO: We're not using this for now but we may want to switch to
    // claims based org, teams and projects retrieval auth is from @/services/Firebase
    // We need to a token refresh to get freshcustom claims
    // IMPORTANT we need to await so we don't get errors reading Firestore "in flight"
    // await auth.currentUser.getIdToken(true)
    // auth.currentUser.getIdTokenResult().then(idTokenResults => {
    //   console.log({ rolesFromToken: idTokenResults?.claims?.roles })
    // })

    get().loadAndListenToUser(userId)

    get().loadAndListenToUserRoles(userId)
  },

  /**
   *  Loads user data from Firestore and listens to changes
   * */
  loadAndListenToUser: async userId => {
    if (!userId) return
    // Unsubscribe from previous listener if exists (if not - calls an empty function)
    // added the if to make sure there's no error when calling on null
    if (get().userListenerUnsubscribeFn) {
      get().userListenerUnsubscribeFn()
    }
    const userRef = doc(firestoreDb, COLLECTIONS.users, userId)
    const unsubscribe = onSnapshot(
      userRef,
      docSnapshot => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data()
          set({ user: userData })

          // console.log(
          //   `User document updated: ${userData?.firstName} ${userData?.lastName} (${docSnapshot.id})`
          // )
        } else {
          console.error('User document does not exist')
          set({ user: {} })
        }
      },
      error => {
        console.error('Error listening to user document: ', error)
      }
    )
    set({ userListenerUnsubscribeFn: unsubscribe })
  },

  /**
   * Loads user roles from Firestore and listends to changes
   * */
  loadAndListenToUserRoles: async userId => {
    if (!userId) return
    // Unsubscribe from previous listener if exists (if not - calls an empty function)
    // added the if to make sure there's no error when calling on null
    if (get().userRolesListenerUnsubscribeFn) {
      get().userRolesListenerUnsubscribeFn()
    }
    const userRolesRef = doc(firestoreDb, COLLECTIONS.userRoles, userId)
    const unsubscribe = onSnapshot(
      userRolesRef,
      docSnapshot => {
        if (docSnapshot.exists()) {
          const userRolesData = docSnapshot.data()
          set({ userRoles: userRolesData?.roles || {} })
          get().loadOrgsTeamsAndProjectsForUserRole()
        } else {
          console.error('User role document does not exist')
          set({ userRoles: {} })
        }
      },
      error => {
        console.error('Error listening to user roles: ', error)
        // Handle the error appropriately
      }
    )
    set({ userRolesListenerUnsubscribeFn: unsubscribe })
  },

  /**
   * Loads organizations, teams and projects from Firestore based on user roles
   * @returns void
   */
  loadOrgsTeamsAndProjectsForUserRole: async () => {
    // Before loading new data (on userRoles object change), unsubscribe from previous listeners that may be present
    get().unsubscribeFromOrgsTeamsProjectsListeners()
    // We need to reset the store before loading new data to avoid duplicates
    // as we need to setup new queries and all objects will be change === "added"
    // also roles may mean less access to objects
    set({
      organizations: [],
      teams: [],
      projects: [],
      isAfterInitialLoad: false,
    })
    const userRoles = get().userRoles
    if (userRoles?.[ACL_ROLES.SUPERADMIN]) {
      // If user is superadmin, load all orgs, teams and projects
      // and don't try to check other roles as those would be irrelevant
      get().loadOrgsTeamsAndProjects__All()
      // don't try to load anything else
      return
    }

    //:TODO: This can be simiplified and made more generic
    // with drilling down to the deepest level of object and checking
    // if it's an object or boolean

    if (userRoles?.[ACL_ROLES.ADMIN]) {
      const role = userRoles.admin
      const keys = Object.keys(role)
      const organizationIds = keys.filter(key => role[key])
      get().loadOrgsTeamsAndProjects__byOrgs(organizationIds)
    }

    if (userRoles?.[ACL_ROLES.MANAGER] || userRoles?.[ACL_ROLES.CREATOR]) {
      const role = _.merge(userRoles?.manager, userRoles?.creator)
      const organizationIds = Object.keys(role)
      const teamIds = Object.values(role).flatMap(teamIds => {
        const keys = Object.keys(teamIds)
        return keys.filter(key => teamIds[key])
      })
      get().loadOrgsTeamsAndProjects__byOrgsAndTeams(organizationIds, teamIds)
    }

    if (userRoles?.[ACL_ROLES.VIEWER]) {
      const role = userRoles.viewer

      // const teamIds = Object.values(role).flatMap(teamIds => Object.keys(teamIds))
      // make sure that we use only projectIds where the under the projectId key is true
      // {orga1: {"teamId": {{projectId1:true}, {projectId2:true}}}}
      const projectIds = Object.values(role).flatMap(teamIds =>
        Object.values(teamIds).flatMap(projectIds => {
          const keys = Object.keys(projectIds)
          return keys.filter(key => projectIds[key])
        })
      )
      //Get team IDs where there's at least one projectId that is true
      const teamIds = Object.values(role).flatMap(teamIds => {
        const keys = Object.keys(teamIds)
        return keys.filter(key => {
          const projectIds = Object.values(teamIds[key])
          return projectIds.some(projectId => projectId)
        })
      })

      // get orga IDs where there's at least one team
      const organizationIds = Object.keys(role).filter(orgId => {
        const teamIds = Object.values(role[orgId]).flatMap(teamIds => {
          const keys = Object.keys(teamIds)
          return keys.filter(key => teamIds[key])
        })
        return teamIds.length > 0
      })

      get().loadOrgsTeamsAndProjects__byOrgsAndTeamsAndProjects(
        organizationIds,
        teamIds,
        projectIds
      )
    }
  },

  /**
   * Updates a collection in the store based on the change type
   * @param {*} collection
   * @param {*} changeType
   * @param {*} docData
   * @returns
   * */
  updateCollection: (collection, changeType, docData) => {
    switch (changeType) {
      case 'added': {
        // Check if the item already exists in the collection
        const index = collection.findIndex(item => item.id === docData.id)
        if (index === -1) {
          // Item does not exist, add it to the collection
          return [...collection, docData]
        } else {
          // Item already exists, replace it with the new data
          // This is useful if the 'added' event is used in a way that might include updates
          const updatedCollection = [...collection]
          updatedCollection[index] = docData
          return updatedCollection
        }
      }
      case 'modified':
        return collection.map(item => (item.id === docData.id ? docData : item))
      case 'removed':
        return collection.filter(item => item.id !== docData.id)
      default:
        return collection
    }
  },

  /**
   * Updates the store based on Firestore changes
   * @param {*} collection
   * @param {*} change
   * @returns
   * It is used in the onSnapshot listener
   * to update the store based on Firestore changes
   * :NOTE: It can be done in a more generic way but I wanted it to be explicit
   * */
  updateData: (collection, change) => {
    const docData = { id: change.doc.id, ...change.doc.data() }
    set(state => {
      // console.log(`--- `, collection.toUpperCase(), change.type.toUpperCase(), docData.id)
      switch (collection) {
        case 'organizations':
          return {
            organizations: get().updateCollection(state.organizations, change.type, docData),
          }
        case 'teams':
          return {
            teams: get().updateCollection(state.teams, change.type, docData),
          }
        case 'projects':
          return {
            projects: get().updateCollection(state.projects, change.type, docData),
          }
        default:
          return {}
      }
    })
    get().setLoaded(collection)
    if (get().isEverythingLoaded()) {
      set({ isAfterInitialLoad: true })
    }
  },

  /**
   * Loads all organizations, teams and projects from Firestore
   * and adds them to the store uniquely
   * user for superadmin role
   */
  loadOrgsTeamsAndProjects__All: async () => {
    // Fetch all documents simultaneously
    const unsubscribeOrgs = onSnapshot(organizationsCollectionRef, snapshot => {
      snapshot.docChanges().forEach(change => {
        get().updateData('organizations', change)
      })
    })

    const unsubscribeTeams = onSnapshot(teamsCollectionRef, snapshot => {
      snapshot.docChanges().forEach(change => {
        get().updateData('teams', change)
      })
    })

    const unsubscribeProjects = onSnapshot(projectsCollectionRef, snapshot => {
      snapshot.docChanges().forEach(change => {
        get().updateData('projects', change)
      })
    })

    get().addUnsubscribeFunction(unsubscribeOrgs)
    get().addUnsubscribeFunction(unsubscribeTeams)
    get().addUnsubscribeFunction(unsubscribeProjects)
  },

  /**
   * Loads organizations, teams and projects from Firestore by organization IDs
   * and adds them to the store uniquely
   * @param {*} organizationIds
   * @returns void
   */
  loadOrgsTeamsAndProjects__byOrgs: async organizationIds => {
    if (!organizationIds.length) {
      console.error('No organization IDs provided')
      return
    }
    const { updateData } = get()

    // Batch the organization IDs
    const batchSize = 10
    const orgIdBatches = createBatches(organizationIds, batchSize)

    orgIdBatches.forEach((idsBatch, idx) => {
      const orgsQuery = query(organizationsCollectionRef, where(documentId(), 'in', idsBatch))
      const teamsQuery = query(teamsCollectionRef, where('organizationId', 'in', idsBatch))
      const projectsQuery = query(projectsCollectionRef, where('organizationId', 'in', idsBatch))

      const unsubscribeOrgs = onSnapshot(orgsQuery, snapshot => {
        if (idx === orgIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('organizations')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('organizations', change)
          })
        }
      })

      const unsubscribeTeams = onSnapshot(teamsQuery, snapshot => {
        if (idx === orgIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('teams')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('teams', change)
          })
        }
      })

      const unsubscribeProjects = onSnapshot(projectsQuery, snapshot => {
        if (idx === orgIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('projects')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('projects', change)
          })
        }
      })

      get().addUnsubscribeFunction(unsubscribeOrgs)
      get().addUnsubscribeFunction(unsubscribeTeams)
      get().addUnsubscribeFunction(unsubscribeProjects)
    })
  },

  /**
   * Loads organizations, teams and projects from Firestore by organization IDs and team IDs
   * and adds them to the store uniquely
   * @param {*} organizationIds
   * @param {*} teamIds
   * @returns void
   */
  loadOrgsTeamsAndProjects__byOrgsAndTeams: async (organizationIds, teamIds) => {
    if (!organizationIds.length) {
      console.error('No organization IDs provided')
      return
    }
    if (!teamIds.length) {
      console.error('No team IDs provided')
      return
    }
    const { updateData } = get()
    // Batch the organization and team IDs
    const batchSize = 10
    const orgIdBatches = createBatches(organizationIds, batchSize)
    const teamIdBatches = createBatches(teamIds, batchSize)

    orgIdBatches.forEach((orgIdsBatch, idx) => {
      const orgsQuery = query(organizationsCollectionRef, where(documentId(), 'in', orgIdsBatch))
      const unsubscribeOrgs = onSnapshot(orgsQuery, snapshot => {
        if (idx === orgIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('organizations')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('organizations', change)
          })
        }
      })

      get().addUnsubscribeFunction(unsubscribeOrgs)
    })

    teamIdBatches.forEach((teamIdsBatch, idx) => {
      const teamsQuery = query(teamsCollectionRef, where(documentId(), 'in', teamIdsBatch))
      const projectsQuery = query(projectsCollectionRef, where('teamId', 'in', teamIdsBatch))

      const unsubscribeTeams = onSnapshot(teamsQuery, snapshot => {
        if (idx === teamIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('teams')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('teams', change)
          })
        }
      })

      const unsubscribeProjects = onSnapshot(projectsQuery, snapshot => {
        if (idx === teamIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('projects')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('projects', change)
          })
        }
      })

      get().addUnsubscribeFunction(unsubscribeTeams)
      get().addUnsubscribeFunction(unsubscribeProjects)
    })
  },

  /**
   * Loads organizations, teams and projects from Firestore by organization IDs,
   * team IDs and project IDs
   * and adds them to the store uniquely
   * @param {*} organizationIds
   * @param {*} teamIds
   * @param {*} projectIds
   * @returns void
   */
  loadOrgsTeamsAndProjects__byOrgsAndTeamsAndProjects: async (
    organizationIds,
    teamIds,
    projectIds
  ) => {
    if (!organizationIds.length) {
      console.error('No organization IDs provided')
      return
    }
    if (!teamIds.length) {
      console.error('No team IDs provided')
      return
    }
    if (!projectIds.length) {
      console.error('No project IDs provided')
      return
    }

    const { updateData } = get()

    // Batch the IDs
    const batchSize = 10
    const orgIdBatches = createBatches(organizationIds, batchSize)
    const teamIdBatches = createBatches(teamIds, batchSize)
    const projectIdBatches = createBatches(projectIds, batchSize)

    orgIdBatches.forEach((orgIdsBatch, idx) => {
      const orgsQuery = query(organizationsCollectionRef, where(documentId(), 'in', orgIdsBatch))
      const unsubscribeOrgs = onSnapshot(orgsQuery, snapshot => {
        if (idx === orgIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('organizations')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('organizations', change)
          })
        }
      })
      get().addUnsubscribeFunction(unsubscribeOrgs)
    })

    teamIdBatches.forEach((teamIdsBatch, idx) => {
      const teamsQuery = query(teamsCollectionRef, where(documentId(), 'in', teamIdsBatch))
      const unsubscribeTeams = onSnapshot(teamsQuery, snapshot => {
        if (idx === teamIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('teams')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('teams', change)
          })
        }
      })
      get().addUnsubscribeFunction(unsubscribeTeams)
    })

    projectIdBatches.forEach((projectIdsBatch, idx) => {
      const projectsQuery = query(projectsCollectionRef, where(documentId(), 'in', projectIdsBatch))
      const unsubscribeProjects = onSnapshot(projectsQuery, snapshot => {
        if (idx === projectIdBatches.length - 1 && snapshot.empty) {
          get().setLoaded('projects')
        } else {
          snapshot.docChanges().forEach(change => {
            updateData('projects', change)
          })
        }
      })
      get().addUnsubscribeFunction(unsubscribeProjects)
    })
  },

  getOrgById: orgId => {
    return get().organizations.find(org => org.id === orgId)
  },
  getTeamsByOrgId: orgId => {
    return get()
      .teams.filter(team => team.organizationId === orgId)
      .sort((a, b) => b.name - a.name)
  },
  getTeamById: teamId => {
    return get().teams.find(team => team.id === teamId)
  },
  getProjectsByTeamId: teamId => {
    return get()
      .projects.filter(project => project.teamId === teamId)
      .sort((a, b) => b.updatedAt - a.updatedAt)
  },
  getProjectsByOrgId: orgId => {
    return get()
      .projects.filter(project => project.organizationId === orgId)
      .sort((a, b) => b.updatedAt - a.updatedAt)
  },
  getProjectById: projectId => {
    return get().projects.find(project => project.id === projectId)
  },
  getProjectsByStatus: status => {
    return get().projects.filter(project => project.status === status)
  },
  /**
   * Returns all projects except those with the provided status
   * @param {*} status
   * @returns
   */
  getProjectsByStatusNot: status => {
    return get().projects.filter(project => project.status !== status)
  },
  getRoleForProject: projectId => {
    const { userRoles, projects } = get()
    const project = projects.find(project => project.id === projectId)
    const { organizationId, teamId } = project
    if (userRoles?.[ACL_ROLES.SUPERADMIN]) {
      return ACL_ROLES.SUPERADMIN
    }
    if (userRoles?.[ACL_ROLES.ADMIN]?.[organizationId]) {
      return ACL_ROLES.ADMIN
    }
    if (userRoles?.[ACL_ROLES.MANAGER]?.[organizationId]?.[teamId]) {
      return ACL_ROLES.MANAGER
    }
    if (userRoles?.[ACL_ROLES.CREATOR]?.[organizationId]?.[teamId]) {
      return ACL_ROLES.CREATOR
    }

    return ACL_ROLES.VIEWER
  },
  isSuperAdmin: () => {
    return get().userRoles?.[ACL_ROLES.SUPERADMIN] === true
  },

  getNewestProjects: (count = 5) => {
    const { projects } = get()
    return projects.sort((a, b) => b.updatedAt - a.updatedAt).slice(0, count)
  },
  getProjectsSortedByUpdatedAt: () => {
    return get().projects.sort((a, b) => b.updatedAt - a.updatedAt)
  },
  getTeamsByIds: teamIds => {
    const filteredTeams = get().teams.filter(team => teamIds.includes(team.id))
    const filteredTeamsMap = _.keyBy(filteredTeams, 'id')
    return filteredTeamsMap
  },
  getProjectBrokenDownByOrganization: () => {
    // Create an array where ech or object has a property projects with that org's projects

    const projectsByOrganization = get().organizations.map(org => ({
      ...org,
      projects: get()
        .projects.filter(project => project.organizationId === org.id)
        .sort((a, b) => b.updatedAt - a.updatedAt),
    }))
    // order organization by most recent projcts
    return projectsByOrganization.sort((a, b) => {
      const aRecentProject = a.projects[0]
      const bRecentProject = b.projects[0]
      if (!aRecentProject && !bRecentProject) return 0
      if (!aRecentProject) return 1
      if (!bRecentProject) return -1
      return bRecentProject.updatedAt - aRecentProject.updatedAt
    })
  },
  getProjectCountsForTeams: (teamIds = []) => {
    const { projects } = get()
    const projectCounts = {}
    teamIds.forEach(teamId => {
      projectCounts[teamId] = projects.filter(project => project.teamId === teamId).length
    })
    return projectCounts
  },
}))

export default useStore
