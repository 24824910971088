import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function LlmResponseAction({ action, isLast = false }) {
  let content = action?.name || 'Response'
  let detailsContent = ''

  const toolCalls = action?.args?.message?.tool_calls

  if (toolCalls) {
    detailsContent += `## ----------------------- TOOL CALLS-----------------------\n\n`
    toolCalls.forEach(toolCall => {
      detailsContent += `### ${toolCall?.type}: ${toolCall?.function?.name}\n`
      let args = toolCall?.function?.arguments

      if (args) {
        try {
          args = JSON.parse(args)
        } catch (err) {
          console.error('Error parsing JSON', err)
        }
      }

      if (args && typeof args === 'object' && Object.keys(args).length > 0) {
        detailsContent += `Arguments\n`
        Object.entries(args).forEach(([key, val]) => {
          detailsContent += `${key}: ${val}\n`
        })
      }
      detailsContent += '\n'
    })

    detailsContent += `## -----------------------/TOOL CALLS-----------------------\n\n`
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={ChatBubbleLeftRightIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="LLM Response" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

LlmResponseAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
