import { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components/catalyst/checkbox'
import { Text } from '@/components/catalyst/text'

import { Description, Fieldset, Label, Field } from '@/components/catalyst/fieldset'

import { Textarea } from '@/components/catalyst/textarea'

import { useToast } from '@/components/ui/use-toast'

import { createIterationFeedbackFirebaseFunction } from '@/services/Firebase'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

export default function OIterationFeedbackDialog({
  iterationId,
  projectId,
  teamId,
  organizationId,
  onFeedbackCreated = () => {
    console.log('Default onFeedbackCreated function called on OIterationFeedbackDialog')
  },
  isOpen,
  onClose = () => {
    console.log('Default onClose function called on OIterationFeedbackDialog')
  },
}) {
  const [sentiment, setSentiment] = useState(null)
  const [reasons, setReasons] = useState([])
  const [comment, setComment] = useState('')
  const [isWorking, setIsWorking] = useState(false)

  const { toast } = useToast()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const isReady = useMemo(() => {
    return sentiment === 'positive' || (sentiment === 'negative' && reasons?.length > 0)
  }, [reasons?.length, sentiment])

  const handleSentiment = value => {
    setSentiment(value)
  }

  const handleReasonChange = (name, value) => {
    if (value) {
      setReasons([...reasons, name])
    } else {
      setReasons(reasons.filter(reason => reason !== name))
    }
  }

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      // Handle form submission here
      if (isWorking) {
        return
      }
      setIsWorking(true)
      const feedbackData = {
        sentiment,
      }
      if (sentiment === 'negative') {
        feedbackData.reasons = reasons
      }

      if (comment && comment !== '') {
        feedbackData.comment = comment
      }
      try {
        await createIterationFeedbackFirebaseFunction({
          iterationId,
          feedbackData,
        })
        analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_FEEDBACK_ON_FINISHED, {
          iterationId,
          projectId,
          teamId,
          organizationId,
          sentiment: feedbackData?.sentiment,
          reasons: feedbackData?.reasons,
          comment: feedbackData?.comment,
        })
        handleClose()
        onFeedbackCreated()
        toast({
          title: 'Feedback submitted 🙏',
          description: 'Thank you for sharing your feedback!',
          status: 'success',
        })
      } catch (error) {
        toast({
          title: 'Error capturing your feedback 😢',
          description: error.message,
          status: 'error',
        })
      } finally {
        setIsWorking(false)
      }
    },
    [
      sentiment,
      reasons,
      comment,
      iterationId,
      isWorking,
      projectId,
      teamId,
      organizationId,
      handleClose,
      onFeedbackCreated,
      toast,
    ]
  )

  useEffect(() => {
    if (sentiment === 'positive') {
      setReasons([])
      setComment('')
    } else if (sentiment === 'negative') {
      setComment('')
    }
  }, [sentiment])

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            <div className="text-2xl">Please share your feedback</div>
          </DialogTitle>
          <DialogDescription>
            We would love to hear your feedback on this iteration.
          </DialogDescription>
          <DialogBody>
            <div className="space-y-12 sm:space-y-16">
              <Field>
                <Label>
                  <div className="text-lg font-bold">Are you happy with the results?</div>
                </Label>
                <div className="flex w-48 justify-between pt-4">
                  <Button
                    type="button"
                    plain={sentiment !== 'positive'}
                    color="zinc"
                    className={`cursor-pointer rounded-full`}
                    onClick={() => handleSentiment('positive')}
                  >
                    <div className="p-4 text-3xl">👍</div>
                  </Button>
                  <Button
                    plain={sentiment !== 'negative'}
                    color="zinc"
                    className="cursor-pointer rounded-full"
                    type="button"
                    onClick={() => handleSentiment('negative')}
                  >
                    <div className="p-4 text-3xl">👎</div>
                  </Button>
                </div>
              </Field>
            </div>
            {sentiment === 'negative' && (
              <Fieldset className="mt-12">
                <Text>We're sorry to hear that. What went wrong?</Text>
                <CheckboxGroup>
                  <CheckboxField>
                    <Checkbox
                      value="plan"
                      checked={reasons.includes('plan')}
                      onChange={value => {
                        handleReasonChange('plan', value)
                      }}
                    />
                    <Label>Planning</Label>
                    <Description>The iteration plan was not good</Description>
                  </CheckboxField>
                  <CheckboxField>
                    <Checkbox
                      value="content"
                      checked={reasons.includes('content')}
                      onChange={value => {
                        handleReasonChange('content', value)
                      }}
                    />
                    <Label>Mock content</Label>
                    <Description>Created content didn't fit my expectations</Description>
                  </CheckboxField>
                  <CheckboxField>
                    <Checkbox
                      value="branding"
                      checked={reasons.includes('branding')}
                      onChange={value => {
                        handleReasonChange('branding', value)
                      }}
                    />
                    <Label>Branding</Label>
                    <Description>Branding was not extracted or applied correctly</Description>
                  </CheckboxField>
                  <CheckboxField>
                    <Checkbox
                      value="code"
                      checked={reasons.includes('code')}
                      onChange={value => {
                        handleReasonChange('code', value)
                      }}
                    />
                    <Label>Code</Label>
                    <Description>Code qualit was not good or there was an error</Description>
                  </CheckboxField>
                  <CheckboxField>
                    <Checkbox
                      value="other"
                      checked={reasons.includes('other')}
                      onChange={value => {
                        handleReasonChange('other', value)
                      }}
                    />
                    <Label>Other reason</Label>
                    <Description>Something else didn't work</Description>
                  </CheckboxField>
                </CheckboxGroup>
                {reasons.includes('other') && (
                  <Textarea
                    placeholder="Care to share more details?"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                )}
              </Fieldset>
            )}
            {sentiment === 'positive' && (
              <Fieldset className="mt-12">
                <Text>We're glad to heat that!</Text>

                <Textarea
                  placeholder="Care to share more details?"
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                />
              </Fieldset>
            )}
          </DialogBody>
          <DialogActions>
            <Button disabled={isWorking} plain onClick={handleClose}>
              Dismiss
            </Button>
            <Button
              disabled={isWorking || !isReady}
              className={isWorking ? 'bg-zinc-600' : ''}
              type="submit"
            >
              {isWorking ? 'Sending...' : 'Submit'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

OIterationFeedbackDialog.propTypes = {
  iterationId: PropTypes.string,
  projectId: PropTypes.string,
  teamId: PropTypes.string,
  organizationId: PropTypes.string,
  onFeedbackCreated: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
