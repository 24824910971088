import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useState } from 'react'

export default function AActionContentTerminal({ content, terminalOutputs, errored = false }) {
  const [showTerminalOutputs, setShowTerminalOutputs] = useState(false)
  const className = classNames(
    'p-2 rounded-t-sm mt-1 break-all',
    terminalOutputs && 'cursor-pointer border-b border-dashed border-zinc-200 ',
    !terminalOutputs && 'rounded-b-sm',
    errored && 'bg-red-700 text-zinc-200',
    !errored && 'bg-black text-green-400'
  )
  return (
    <>
      <div
        className={className}
        onClick={() => {
          setShowTerminalOutputs(!showTerminalOutputs)
        }}
      >
        {content}
      </div>
      {!!terminalOutputs && showTerminalOutputs && (
        <div className="tex-xs flex w-full max-w-full flex-col rounded-b-sm bg-black p-4 pt-1 font-mono font-normal text-neutral-300">
          <div>Std out:</div>
          {terminalOutputs?.map((content, index) => (
            <div key={index} className="flex-grow-0 break-all">
              $ {content}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

AActionContentTerminal.propTypes = {
  content: PropTypes.string,
  terminalOutputs: PropTypes.arrayOf(PropTypes.string),
}
