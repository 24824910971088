import { useEffect, useMemo, useState } from 'react'
import { NavLink, Outlet, Route, Routes, useParams } from 'react-router-dom'

import _ from 'lodash'
import classNames from 'classnames'
import { collection, orderBy, query } from 'firebase/firestore'
import { useCollection } from 'react-firebase-hooks/firestore'
import useStore from '@/stores/useStore'

// import { ACL_ROLES } from '@/const/const'
import { COLLECTIONS, projectsCollectionRef } from '@/services/Firebase'

import OPageWrapper from '@/components/organisms/OPageWrapper'
import MFourOFour from '@/components/molecules/MFourOFour'

import MProjectHeader from '@/components/molecules/project-details/MProjectHeader'
import OIterationsListAndDetails from '@/components/organisms/project-details/OIterationsListAndDetails'
import OProjectHintListAndDetails from '@/components/organisms/project-details/OProjectHintListAndDetails'
import MIterationsEmptyState from '@/components/molecules/project-details/MIterationsEmptyState'
import MNewIterationDialog from '@/components/molecules/iteration-details/MNewIterationDialog'

import { isSuperadmin as isSuperAdminFn } from '@/lib/acl-utils'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

/**
 * @typedef {Object} Project
 * @property {string} id
 * @property {string} name
 * @property {string} prompt
 * @property {string} status
 * @property {string} image
 */

/**
 * @typedef {Object} Iteration
 * @property {string} id
 * @property {string} projectId
 * @property {string} status
 * @property {number} elapsedTime
 * @property {number} inputTokens
 * @property {number} outputTokens
 * @property {number} cost
 * @property {number} createdAt
 * @property {number} updatedAt
 */

/**
 * @typedef {Object} ProjectDetailsProps
 * @property {Project} project
 * @property {Iteration[]} iterations
 */

const tabs = [
  { name: 'Iterations', href: '', current: true },
  { name: 'Hints', href: 'hints', current: false },
]

const MINUTE_IN_MS = 60000
const INTERFACE_STATES = {
  LOADING: 'loading',
  ERROR: 'error',
  NO_ITERATIONS: 'no-iterations',
  HAS_ITERATIONS: 'has-iterations',
  PROJECT_NOT_FOUND: 'project-not-found',
}

/**
 * @param {object} props
 * @returns {JSX.Element}
 */
export default function PRojectDetails() {
  const { projectId } = useParams()
  const [lastTick, setLastTick] = useState(Date.now())
  const [interfaceState, setWhatToShow] = useState(INTERFACE_STATES.LOADING)
  const [isNewIterationModalOpen, setIsNewIterationModalOpen] = useState(false)

  const { projects, getProjectById, organizations, teams, getOrgById, getTeamById, userRoles } =
    useStore(state => ({
      projects: state.projects,
      getProjectById: state.getProjectById,
      organizations: state.organizations,
      teams: state.teams,
      getOrgById: state.getOrgById,
      getTeamById: state.getTeamById,
      userRoles: state.userRoles,
    }))

  // const getRoleForProject = useStore(state => state.getRoleForProject)

  // Ticks every minute to update relative times in the UI
  useEffect(() => {
    const interval = setInterval(() => {
      setLastTick(Date.now())
    }, MINUTE_IN_MS)
    return () => clearInterval(interval)
  }, [])

  const isSuperAdmin = useMemo(() => {
    return isSuperAdminFn(userRoles)
  }, [userRoles])

  const viewableIterationsQuery = useMemo(() => {
    if (!projectId) {
      return null
    }
    return query(
      collection(projectsCollectionRef, projectId, COLLECTIONS.projectsViewableIterationsMeta),
      orderBy('createdAt', 'desc')
    )
  }, [projectId])

  // Actual iterations retrieval
  const [viewableIterationSnapshot, viewableIterationsLoading] =
    useCollection(viewableIterationsQuery)

  const viewableIterations = useMemo(() => {
    if (!viewableIterationSnapshot) {
      return []
    }
    return viewableIterationSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  }, [viewableIterationSnapshot])

  const areAllIterationsDone = useMemo(() => {
    if (!viewableIterations) {
      return true
    }
    return viewableIterations.every(iteration => iteration.status === 'DONE')
  }, [viewableIterations])

  const project = useMemo(() => {
    if (projects.length > 0) {
      return getProjectById(projectId)
    } else {
      return null
    }
  }, [projects, getProjectById, projectId])

  const organization = useMemo(() => {
    if (organizations.length > 0) {
      return getOrgById(project?.organizationId)
    } else {
      return null
    }
  }, [organizations, getOrgById, project])

  const team = useMemo(() => {
    if (teams.length > 0) {
      return getTeamById(project?.teamId)
    } else {
      return null
    }
  }, [teams, getTeamById, project])

  useEffect(() => {
    if (viewableIterationsLoading) {
      setWhatToShow(INTERFACE_STATES.LOADING)
    } else if (projects?.length === 0 || organizations?.length === 0 || teams?.length === 0) {
      setWhatToShow(INTERFACE_STATES.LOADING)
    } else if (!project) {
      setWhatToShow(INTERFACE_STATES.PROJECT_NOT_FOUND)
    } else if (!viewableIterationsLoading && viewableIterationSnapshot?.empty) {
      setWhatToShow(INTERFACE_STATES.NO_ITERATIONS)
    } else {
      setWhatToShow(INTERFACE_STATES.HAS_ITERATIONS)
    }
  }, [
    projects,
    organizations,
    teams,
    viewableIterationsLoading,
    viewableIterationSnapshot,
    project,
  ])

  useEffect(() => {
    if (project?.id) {
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_OPEN, {
        projectId: project?.id,
        projectName: project?.name,
        organizationId: project?.organizationId,
        teamId: project?.teamId,
      })
    }
  }, [project?.id, project?.name, project?.organizationId, project?.teamId])

  // const role = useMemo(() => {
  //   if (project) {
  //     return getRoleForProject(project.id)
  //   } else {
  //     return ACL_ROLES.VIEWER
  //   }
  // }, [project, getRoleForProject])

  /** @type {{ name: string, current: boolean, href: string }[]} */
  const breadcrumbs = useMemo(() => {
    return [
      {
        name: organization?.name || 'Organization',
        href: `/organizations/${project?.organizationId}`,
      },
      {
        name: team?.name || 'Team',
        href: `/teams/${project?.teamId}`,
      },
      {
        name: project?.name || 'Project',
        current: true,
      },
    ]
  }, [organization, team, project])

  const [totalInputTokens, totalOutputTokens, totalElapsedTime, totalCost] = useMemo(() => {
    if (!viewableIterations) {
      return [0, 0, 0]
    }
    const { totalInputTokens, totalOutputTokens, totalElapsedTime } = viewableIterations.reduce(
      (acc, iteration) => {
        acc.totalInputTokens += iteration?.inputTokens || 0
        acc.totalOutputTokens += iteration?.outputTokens || 0
        acc.totalElapsedTime += iteration?.elapsedTime || 0
        return acc
      },
      { totalInputTokens: 0, totalOutputTokens: 0, totalElapsedTime: 0 }
    )
    const totalCost = _.round(totalInputTokens * 0.000002 + totalOutputTokens * 0.000004, 2)

    return [totalInputTokens, totalOutputTokens, totalElapsedTime, totalCost]
  }, [viewableIterations])

  // const handleCreateTestIteration = useCallback(
  //   async data => {
  //     if (!project || isIterationCreating) {
  //       return
  //     }
  //     try {
  //       setIsIterationCreating(true)
  //       // // const testData = {
  //       // //   projectId: project.id,
  //       // //   iteration: {
  //       // //     description: 'Test iteration description',
  //       // //     shouldBeDeleted: true,
  //       // //     index: 0,
  //       // //     prompt: data?.prompt
  //       // //       ? data.prompt
  //       // //       : 'Test prompt created on ' + new Date().toISOString(),
  //       // //   },
  //       // //   // environment: { key1: 'value1', key2: 'value2', shouldBeDeleted: true },
  //       // //   sourceIterationId: activeIterationId,
  //       // //   // environmentId: '9aWtLBdOhnGg7tQARMam',
  //       // // }
  //       // // const iteration = await createIterationForProject(testData)
  //       // console.log({ iteration })
  //       console.log('Not working yet')
  //     } catch (error) {
  //       console.log({ code: error.code, message: error.message })
  //       setIsIterationCreating(false)
  //     }
  //     // We're not setting that iterations was created because we're listening to the collection
  //   },
  //   [project, isIterationCreating, activeIterationId]
  // )

  if (interfaceState === INTERFACE_STATES.LOADING) {
    return (
      <OPageWrapper breadcrumbs={breadcrumbs}>
        <div></div>
      </OPageWrapper>
    )
  }

  if (interfaceState === INTERFACE_STATES.PROJECT_NOT_FOUND) {
    return (
      <OPageWrapper breadcrumbs={breadcrumbs}>
        <MFourOFour
          title="Project not found"
          description={`Project doesn't exist or you don't have access to it`}
        />
      </OPageWrapper>
    )
  }

  if (interfaceState === INTERFACE_STATES.NO_ITERATIONS) {
    return (
      <OPageWrapper breadcrumbs={breadcrumbs}>
        <MIterationsEmptyState
          onCtaClick={() => {
            setIsNewIterationModalOpen(true)
          }}
        />
        <MNewIterationDialog
          isOpen={isNewIterationModalOpen}
          projectId={project?.id}
          onClose={() => {
            setIsNewIterationModalOpen(false)
          }}
          isFirstIterationForProject={true}
          onSubmitted={data => {
            console.log('data received from iteration dialog', { data })
          }}
        />
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <div className="mb-8 mt-8">
        <MProjectHeader
          projectName={project?.name}
          teamName={team?.name}
          organizationName={organization?.name}
          areAllIterationsDone={areAllIterationsDone}
          inputTokens={totalInputTokens}
          outputTokens={totalOutputTokens}
          elapsedTime={totalElapsedTime}
          image={project?.image || null}
          cost={totalCost}
        />
      </div>
      {isSuperAdmin && (
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map(tab => (
                <NavLink
                  end
                  key={tab.name}
                  to={tab.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'border-zinc-500 text-zinc-600'
                        : 'border-transparent text-zinc-500 hover:border-zinc-300 hover:text-zinc-700',
                      'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                    )
                  }
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      )}
      <Routes>
        {isSuperAdmin && (
          <Route
            path="hints"
            exact
            element={
              <OProjectHintListAndDetails
                key={projectId}
                organizationId={project?.organizationId}
                projectId={projectId}
                lastTick={lastTick}
              />
            }
          />
        )}
        <Route
          path="*"
          element={
            <OIterationsListAndDetails
              key={projectId}
              iterations={viewableIterations}
              iterationsLoading={viewableIterationsLoading}
              lastTick={lastTick}
            />
          }
        />
      </Routes>
      <Outlet />
    </OPageWrapper>
  )
}

PRojectDetails.propTypes = {}
